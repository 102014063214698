import React from "react"
import Axios from "axios"
import { Form, Card, Col, Row, CardBody, Input, DropdownItem, DropdownMenu, DropdownToggle, ButtonDropdown } from "reactstrap";

import MetaTags from 'react-meta-tags'
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ReactApexChart from "react-apexcharts"
// Nir...
import MyFunction from "../common/MyFunction"
import { Loader } from "../common/MyStyle"

class Income extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            labels: [],
            series: [],
            options: {},
            fromDate: '',
            toDate: '',
            loading: false
        }
        this.setGraphData = this.setGraphData.bind(this);
        this.filterData = this.filterData.bind(this)
        this.updateTimeRange = this.updateTimeRange.bind(this)
        this.handleTimePeriodChange = this.handleTimePeriodChange.bind(this)
        this.changeMonth = this.changeMonth.bind(this)
        this.setInitialTimePeriod = this.setInitialTimePeriod.bind(this)
    }

    componentDidMount() {
        this.sessionUid = MyFunction.loginUid()
        this.setInitialTimePeriod()
    }

    setGraphData() {
        this.setState({
            series: this.state.series,
            options: {
                labels: this.state.labels,
                colors: ["#34c38f", "#556ee6", "#f46a6a", "#50a5f1", "#f1b44c"],
                legend: {
                    show: true,
                    position: "left",
                    horizontalAlign: "center",
                    verticalAlign: "middle",
                    floating: false,
                    fontSize: "14px",
                    offsetX: 0,
                    offsetY: -10,
                    labels: {

                    }
                },
                yaxis: {
                    labels: {
                        formatter: function (value) {
                            return (MyFunction.toIndianCurrency(value.toString()))
                        }
                    }
                },
                plotOptions: {
                    pie: {
                        expandOnClick: true
                    }
                },
                fill: {
                    type: 'gradient',
                },
                chart: {
                    toolbar: {
                        show: true,
                        tools: {
                            download: true,
                            selection: false,
                            zoom: false,
                            zoomin: false,
                            zoomout: false,
                            pan: false,
                            reset: false
                        },
                    },
                },
                responsive: [{
                    breakpoint: 600,
                    options: {
                        chart: {
                            height: 240,
                        },
                        legend: {
                            show: false,
                        },
                    },
                },],
            },
        })
    }

    getAllData() {
        this.setState({ loading: true })
        Axios.post(`${MyFunction.global.url}/get_all_income.php`, {
            uid: this.sessionUid,
            from_date: this.state.fromDate,
            to_date: this.state.toDate,
        }).then((response) => {
            // console.log(response.data);
            // console.log(response.data[1]['amount']);
            var col1 = [], col2 = []
            for (var i = 0; i < response.data.length; ++i) {
                col1[i] = response.data[i]['account'];
                col2[i] = Number(response.data[i]['amount']);
            }
            this.setState({ labels: col1, series: col2, loading: false }, () => {
                this.setGraphData()
            })
        });
    }

    // SETS THE INITIAL DATE AT THE START OF APP...
    setInitialTimePeriod() {
        var date = new Date()
        var oneYearFromToday = new Date(date.getFullYear() - 1, date.getMonth(), 1)
        var today = date
        this.updateTimeRange(oneYearFromToday, today)
    }

    // FILTER DATA...
    filterData(event) {
        event.preventDefault()
        this.setState({ timePeriod: '-' })
        // this.getRecordCount()
        this.getAllData()
    }
    // TIME PERIOD CHANGE USING DROPDOWN...
    handleTimePeriodChange(e) {
        // alert(e.target.value)
        var period = e.target.value
        if (period != '-' && period != undefined && period != '') {
            this.setState({ timePeriod: period })
            var date = new Date();
            // date = new Date(date.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' }))
            if (period == 'tm') {
                var firstDayOfThisMonth = new Date(date.getFullYear(), date.getMonth(), 1)
                var lastDayOfThisMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0)
                this.updateTimeRange(firstDayOfThisMonth, lastDayOfThisMonth)
            } else if (period == 'fy') {
                var firstDayofFY = new Date(MyFunction.getFinancialYearStart())
                var lastDayofFY = new Date(MyFunction.getFinancialYearEnd())
                this.updateTimeRange(firstDayofFY, lastDayofFY)
            } else {
                this.updateTimeRange(new Date("2000-01-01"), date)
            }
        }
    }
    // UPDATES DATA ON DATE RANGE CHANGE FROM ANYWHERE...
    updateTimeRange(fromDate, toDate) {
        this.setState({
            fromDate: MyFunction.formatDate(fromDate),
            toDate: MyFunction.formatDate(toDate)
        }, function () {
            // this.getRecordCount()
            this.getAllData()
        })
    }
    // MONTH INCREASE/DECREASE...
    changeMonth(np) {
        var fromDate = new Date(this.state.fromDate)
        var toDate = new Date(this.state.toDate)
        // alert(fromDate)
        if (np == 'pre') {
            var newDate = new Date(fromDate.getFullYear(), fromDate.getMonth() - 1, fromDate.getDate())
            this.updateTimeRange(newDate, toDate)
        } else if (np == 'nxt') {
            var newDate = new Date(toDate.getFullYear(), toDate.getMonth() + 1, toDate.getDate())
            this.updateTimeRange(fromDate, newDate)
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>MyM-Account</title>
                    </MetaTags>
                    <div className="container-fluid">
                        <Breadcrumbs title="Dashboard" link1="/dashboard" breadcrumbItem="Income" />
                        <Card>
                            <CardBody>
                                <Form onSubmit={this.filterData} className="row align-items-center">
                                    <Row>
                                        <Col sm={2} xs={12} className="space-around">
                                            <Input
                                                value={this.state.fromDate}
                                                onChange={(e) => this.setState({ fromDate: e.target.value, timePeriod: '-' })}
                                                type="date"
                                                className="form-control"
                                            />
                                        </Col>
                                        <Col sm={3} xs={12} align="center" className="space-around" >
                                            {/* <Col className="col-md-3 align-items-center"> */}
                                            <button onClick={() => this.changeMonth("pre")} type="button" className="btn btn-outline-warning">
                                                &nbsp;<i className="bx bx-skip-previous font-size-20 align-middle me-1"></i>
                                            </button>&nbsp;&nbsp;
                                            <button onClick={() => this.setInitialTimePeriod()} type="button" className="btn btn-outline-success">
                                                &nbsp;<i className="bx bx-revision font-size-20 align-middle me-1"></i>
                                            </button>&nbsp;&nbsp;
                                            <button onClick={() => this.changeMonth("nxt")} type="button" className="btn btn-outline-warning">
                                                &nbsp;<i className="bx bx-skip-next font-size-20 align-middle me-1"></i>
                                            </button>
                                        </Col>
                                        <Col sm={2} xs={12} className="space-around">
                                            <Input
                                                value={this.state.toDate}
                                                onChange={(e) => this.setState({ toDate: e.target.value, timePeriod: '-' })}
                                                type="date"
                                                className="form-control"
                                            />
                                        </Col>
                                        <Col sm={1} xs={2} aligh="right" className="space-around">
                                            <button type="submit" className="btn btn-outline-primary">
                                                <i className="bx bx-search-alt font-size-20 align-middle"></i>
                                            </button>
                                        </Col>
                                        <Col sm={2} xs={2} align="left" className="space-around">
                                            <ButtonDropdown
                                                onClickCapture={this.handleTimePeriodChange}
                                                isOpen={this.state.periodToggle}
                                                toggle={() => this.setState({ periodToggle: !this.state.periodToggle })}
                                            >
                                                <DropdownToggle caret color="primary" >
                                                    <i className="mdi mdi-calendar-multiple"></i>
                                                    <i className="mdi mdi-chevron-down"></i>
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem value="tm">This Month</DropdownItem>
                                                    <DropdownItem value="fy">Financial Year</DropdownItem>
                                                    <DropdownItem divider />
                                                    <DropdownItem value="bot">Beginning Of Time</DropdownItem>
                                                </DropdownMenu>
                                            </ButtonDropdown>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                        <Row>
                            {this.state.loading ? <Loader /> : ""}
                            <Col className="col-12">
                                <Card>
                                    <CardBody>
                                        <React.Fragment>
                                            <ReactApexChart
                                                options={this.state.options}
                                                series={this.state.series}
                                                type="donut"
                                                height="320"
                                                className="apex-charts"
                                            />
                                        </React.Fragment>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment >
        )
    }
}
export default Income
