import React from 'react'
import "../common/nirstyle.scss"
import loaderImage from "../../assets/images/nir/loader.gif";

// CUSTOM CHECKBOX...
export const OffSymbol = () => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 12,
                color: "#fff",
                paddingRight: 2
            }}
        >
            {" "}
            No
        </div>
    )
}
export const OnSymbol = () => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 12,
                color: "#fff",
                paddingRight: 2
            }}
        >
            {" "}
            Yes
        </div>
    )
}
// LOADER...
export const Loader = () => {
    return (
        <div className="loader">
            <img className="img" src={loaderImage} />
        </div>
    )
}