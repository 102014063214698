import React from "react"
import Axios from "axios"
import { Row, Col, Card, CardBody, Form, Input, InputGroup } from "reactstrap"
import MetaTags from 'react-meta-tags'
import SweetAlert from "react-bootstrap-sweetalert"
import { Link } from "react-router-dom"
// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../common/datatables.scss"
// Nir...
import MyFunction from "../common/MyFunction"
import "../common/nirstyle.scss"

class AccountManage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            sessionUid: '',
            page: 1,
            sizePerPage: 50,
            recordCount: 0,
            myData: [],
            actionOnId: 0,
            getDeleteConf: false,
            alertDeleted: false,
            searchData: '',
            sortField: '',
            sortOrder: '',
        }
        this.getAllData = this.getAllData.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.callDeleteAPI = this.callDeleteAPI.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.tablePageChange = this.tablePageChange.bind(this);
        this.filterData = this.filterData.bind(this);
        this.handleActive = this.handleActive.bind(this);
        this.loadFresh = this.loadFresh.bind(this);
    }

    componentDidMount() {
        this.sessionUid = MyFunction.loginUid()
        this.loadFresh()
    }

    //--- LOAD EVERYING ON FIRST LOAD & AFTER DELETION ---

    loadFresh() {
        this.getRecordCount()
        this.setState({
            page: 1,
            sizePerPage: 50,
        }, () => { this.getAllData() })
    }

    //--- GET DATA FROM DB ---

    getRecordCount() {
        Axios.post(`${MyFunction.global.url}/get_all_account.php`, {
            count_record: 'y',
            search_data: this.state.searchData,
            uid: this.sessionUid
        }).then((response) => {
            // console.log(response.data);
            // console.log(response.data[0].record_count);
            this.setState({ recordCount: response.data[0].record_count })
        });
    }

    getAllData() {
        Axios.post(`${MyFunction.global.url}/get_all_account.php`, {
            start_page: (parseInt(this.state.page) - 1) * parseInt(this.state.sizePerPage),
            size_of_page: this.state.sizePerPage,
            search_data: this.state.searchData,
            sort_field: this.state.sortField,
            sort_order: this.state.sortOrder,
            uid: this.sessionUid,
        }).then((response) => {
            // console.log(response.data);
            this.setState({ myData: response.data })
            // console.log(this.state.myData);
            // console.log(this.state.myData.length)
        });
    }

    // EDIT DATA...
    handleEdit(e) {
        var actionOnId = e.target.id
        // alert('id: ' + actionOnId)
        window.location.href = "account-update/" + actionOnId
    }

    // DELETE DATA...
    handleDelete(e) {
        this.setState({
            actionOnId: e.target.id,
            getDeleteConf: true,
        })
    }

    callDeleteAPI() {
        // alert(MyFunction.global.url + "/ delete_account.php ? id = " + this.state.actionOnId)
        this.setState({
            getDeleteConf: false
        })
        Axios.delete(`${MyFunction.global.url}/delete_account.php?id=${this.state.actionOnId}`).then(() => {
            // alert("Successfully deleted.")
            this.setState({
                // Remove deleted record from datatable...
                myData: this.state.myData.filter((myData) => {
                    // alert(this.state.actionOnId)
                    return myData.id !== this.state.actionOnId
                }),
                getDeleteConf: false,
                actionOnId: 0,
                alertDeleted: true
            })
        });
        this.loadFresh()
    }

    // --- UPDATE ACTIVE STATE OF THE RECORD ---

    handleActive(e) {
        let id_active = e.target.id
        let id = id_active.slice(0, -1)     // get ID
        let active = id_active.slice(-1)    // get active Y/N
        let newActive = active == 'Y' ? 'N' : 'Y'
        // alert("in")
        Axios.post(`${MyFunction.global.url}/update_account_active.php`, {
            edit_id: id,
            new_active: newActive
        })
    }

    // --- RENDER DYNAMIC TABLE FROM SERVER ---
    filterData(e) {
        // alert(this.state.searchData)
        e.preventDefault()
        this.getRecordCount()
        this.getAllData()
    }

    tablePageChange(type, { page, sortField, sortOrder, sizePerPage }) {
        // alert(page + " " + sortField + " " + sortOrder + " " + sizePerPage)
        this.setState({
            page: page,
            sortField: sortField,
            sortOrder: sortOrder,
            sizePerPage: sizePerPage,
        }, () => { this.getAllData() });
    }

    render() {
        const columns = [{
            dataField: 'account',
            text: 'Account',
            sort: true,
            headerStyle: { backgroundColor: MyFunction.datatableTitleBG }
        }, {
            dataField: 'agroup',
            text: 'Account Group',
            sort: true,
            headerStyle: { backgroundColor: MyFunction.datatableTitleBG }
        }, {
            dataField: 'open_bal',
            text: 'Opening Balance',
            align: 'right',
            formatter: (dataField) => {
                return (MyFunction.toIndianCurrency(dataField));
            },
            headerStyle: { backgroundColor: MyFunction.datatableTitleBG }
        }, {
            dataField: 'liquidity',
            text: 'Liquidity',
            headerStyle: { backgroundColor: MyFunction.datatableTitleBG }
        }, {
            dataField: 'min_bal',
            text: 'Minimum Balance',
            align: 'right',
            formatter: (dataField) => {
                return (MyFunction.toIndianCurrency(dataField));
            },
            headerStyle: { backgroundColor: MyFunction.datatableTitleBG }
        }, {
            dataField: 'id_active',
            text: 'Active',
            sort: true,
            align: 'center',
            formatter: (dataField) => (
                <div className="form-check form-switch mb-3" dir="ltr">
                    <input
                        type="checkbox"
                        className="form-check-input"
                        id={dataField}
                        defaultChecked={dataField.slice(-1) == 'Y' ? true : false}
                        onClick={this.handleActive}
                    />
                </div>
            ),
            headerStyle: { backgroundColor: MyFunction.datatableTitleBG }
        }, {
            dataField: 'id',
            text: "Action",
            headerAlign: 'left',
            align: 'center',
            headerStyle: { backgroundColor: MyFunction.datatableTitleBG },
            formatter: (dataField) => (
                <div className="d-flex gap-3">
                    <Link to={`/account-update/${dataField}`} className="text-success">
                        <i className="mdi mdi-pencil font-size-18" id={dataField} />
                    </Link>
                    <Link to="#" className="text-danger">
                        <i className="mdi mdi-delete font-size-18" id={dataField} onClick={this.handleDelete} />
                    </Link>
                    <Link to={`/transaction-analyse/account/${dataField}`} className="text-warning">
                        <i className="mdi mdi-file-eye font-size-18" id={dataField} />
                    </Link>

                </div>
            ),
        },];

        const defaultSorted = [{
            dataField: 'id',
            order: 'asc'
        }];

        const pageOptions = {
            sizePerPage: this.state.sizePerPage,
            totalSize: this.state.myData.length, // replace later with size(customers),
            custom: true,
        }

        const ServerDataTable = ({ data, page, sizePerPage, totalSize }) => (
            <div>
                <BootstrapTable
                    remote
                    keyField="id"
                    data={data}
                    columns={columns}
                    pagination={paginationFactory({ page, sizePerPage, totalSize })}
                    onTableChange={this.tablePageChange}
                />
            </div>
        );

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>MyM-Account</title>
                    </MetaTags>
                    <div className="container-fluid">
                        <Breadcrumbs title="Dashboard" link1="/dashboard" breadcrumbItem="Account Manage" />
                        <Card>
                            <CardBody>
                                <Row>
                                    <Form onSubmit={this.filterData} className="row align-items-center">
                                        <Col sm={3} xs={6} >
                                            <div className="col-sm-auto">
                                                <InputGroup>
                                                    <div className="input-group-text">
                                                        <i className="bx bx-search-alt search-icon" />
                                                    </div>
                                                    <input
                                                        onChange={(e) => this.setState({ searchData: e.target.value })}
                                                        type="input"
                                                        className="form-control"
                                                        placeholder="Search..."
                                                    />
                                                </InputGroup>
                                            </div>
                                        </Col>
                                        <Col sm={9} xs={6} align="right">
                                            <Link to="/account-update" className="text-danger">
                                                <button type="button" className="btn btn-primary">
                                                    <i className="mdi mdi-plus label-icon"></i> Add New
                                                </button>
                                            </Link>
                                        </Col>
                                    </Form>
                                </Row>
                            </CardBody>
                        </Card>
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <React.Fragment>
                                            <div className="table-responsive">
                                                {this.state.myData.length > 0 ?
                                                    <ServerDataTable
                                                        data={this.state.myData}
                                                        page={this.state.page}
                                                        sizePerPage={this.state.sizePerPage}
                                                        totalSize={this.state.recordCount}
                                                    />
                                                    : "Nothing to show. Please refine your search."}
                                            </div>
                                        </React.Fragment>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    {this.state.alertDeleted ? (
                        <SweetAlert
                            success
                            title="Successfully Deleted"
                            timeout={1000}
                            showConfirm={false}
                            onConfirm={() => this.setState({ alertDeleted: false })}
                        />
                    ) : null}
                    {this.state.getDeleteConf ? (
                        <SweetAlert
                            title="Delete the record?"
                            warning
                            showCancel
                            confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            onConfirm={this.callDeleteAPI}
                            onCancel={() =>
                                this.setState({
                                    getDeleteConf: false,
                                })}
                        >
                            Action can&apos;t be reverted!
                        </SweetAlert>
                    ) : null}
                </div>
            </React.Fragment>
        )
    }
}
export default AccountManage