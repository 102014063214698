import React, { Component } from "react";
import PropTypes from 'prop-types';
import ReactDrawer from 'react-drawer';
import 'react-drawer/lib/react-drawer.css';

import { connect } from "react-redux";

import { Link } from "react-router-dom";

// reactstrap
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import RightSidebar from '../CommonForBoth/RightSidebar';

import logo from "../../assets/images/logo-dark.png";
import logoLight from "../../assets/images/logo-dark.png";
import logoLightSvg from "../../assets/images/logo-dark.png";
import logoDark from "../../assets/images/logo-dark.png";

// import images
import github from "../../assets/images/brands/github.png";
import bitbucket from "../../assets/images/brands/bitbucket.png";
import dribbble from "../../assets/images/brands/dribbble.png";
import dropbox from "../../assets/images/brands/dropbox.png";
import mail_chimp from "../../assets/images/brands/mail_chimp.png";
import slack from "../../assets/images/brands/slack.png";

// Redux Store
import { toggleRightSidebar } from "../../store/actions";

//i18n
import { withTranslation } from "react-i18next";

class Header extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isSearch: false,
            open: false,
            position: 'right',
        }
        this.toggleMenu = this.toggleMenu.bind(this)
        this.toggleFullscreen = this.toggleFullscreen.bind(this)
        this.toggleSearch = this.toggleSearch.bind(this)
        this.toggleRightDrawer = this.toggleRightDrawer.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
        this.onDrawerClose = this.onDrawerClose.bind(this);
    }

    toggleSearch = () => {
        this.setState({ isSearch: !this.state.isSearch })
    }
    /**
     * Toggle sidebar
     */
    toggleMenu() {
        this.props.openLeftMenuCallBack()
    }

    /**
     * Toggles the sidebar
     */
    toggleRightDrawer() {
        this.setState({ position: 'right' });
        this.setState({ open: !this.state.open });
    }
    closeDrawer() {
        this.setState({ open: false });
    }
    onDrawerClose() {
        this.setState({ open: false });
    }

    toggleFullscreen() {
        if (
            !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
            !document.webkitFullscreenElement
        ) {
            // current working methods
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen()
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen()
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(
                    Element.ALLOW_KEYBOARD_INPUT
                )
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen()
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen()
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen()
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <header id="page-topbar">
                    <div className="navbar-header">
                        <div className="d-flex">
                            <div className="navbar-brand-box">
                                <Link to="/" className="logo logo-dark">
                                    <span className="logo-sm">
                                        <img src={logo} alt="" height="22" />
                                    </span>
                                    <span className="logo-lg">
                                        <img src={logoDark} alt="" height="17" />
                                    </span>
                                </Link>

                                <Link to="/" className="logo logo-light">
                                    <span className="logo-sm">
                                        <img src={logoLightSvg} alt="" height="22" />
                                    </span>
                                    <span className="logo-lg">
                                        <img src={logoLight} alt="" height="19" />
                                    </span>
                                </Link>
                            </div>

                            <button
                                type="button"
                                className="btn btn-sm px-3 font-size-16 d-lg-none header-item"
                                data-toggle="collapse"
                                onClick={this.toggleMenu}
                                data-target="#topnav-menu-content"
                            >
                                <i className="fa fa-fw fa-bars" />
                            </button>
                        </div>

                        <div className="d-flex">
                            <LanguageDropdown />
                            <Dropdown
                                className="d-none d-lg-inline-block ms-1"
                                isOpen={this.state.socialDrp}
                                toggle={() => {
                                    this.setState({ socialDrp: !this.state.socialDrp })
                                }}
                            >
                                <DropdownToggle
                                    className="btn header-item noti-icon"
                                    caret
                                    tag="button"
                                >
                                    <i className="bx bx-customize" />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-lg dropdown-menu-end">
                                    <div className="px-lg-2">
                                        <Row className="no-gutters">
                                            <Col>
                                                <Link className="dropdown-icon-item" to="#">
                                                    <img src={github} alt="Github" />
                                                    <span>GitHub..</span>
                                                </Link>
                                            </Col>
                                            <Col>
                                                <Link className="dropdown-icon-item" to="#">
                                                    <img src={bitbucket} alt="bitbucket" />
                                                    <span>Bitbucket</span>
                                                </Link>
                                            </Col>
                                            <Col>
                                                <Link className="dropdown-icon-item" to="#">
                                                    <img src={dribbble} alt="dribbble" />
                                                    <span>Dribbble</span>
                                                </Link>
                                            </Col>
                                        </Row>
                                        <Row className="no-gutters">
                                            <Col>
                                                <Link className="dropdown-icon-item" to="#">
                                                    <img src={dropbox} alt="dropbox" />
                                                    <span>Dropbox</span>
                                                </Link>
                                            </Col>
                                            <Col>
                                                <Link className="dropdown-icon-item" to="#">
                                                    <img src={mail_chimp} alt="mail_chimp" />
                                                    <span>Mail Chimp</span>
                                                </Link>
                                            </Col>
                                            <Col>
                                                <Link className="dropdown-icon-item" to="#">
                                                    <img src={slack} alt="slack" />
                                                    <span>Slack</span>
                                                </Link>
                                            </Col>
                                        </Row>
                                    </div>
                                </DropdownMenu>
                            </Dropdown>
                            <div className="dropdown d-none d-lg-inline-block ms-1">
                                <button
                                    type="button"
                                    className="btn header-item noti-icon"
                                    onClick={this.toggleFullscreen}
                                    data-toggle="fullscreen"
                                >
                                    <i className="bx bx-fullscreen" />
                                </button>
                            </div>
                            <ProfileMenu />
                        </div>
                    </div>
                </header>
                <ReactDrawer
                    open={this.state.open}
                    position={this.state.position}
                    onClose={this.onDrawerClose}
                >
                    <RightSidebar onClose={this.onDrawerClose} />
                </ReactDrawer>
            </React.Fragment>
        )
    }
}

Header.propTypes = {
    openLeftMenuCallBack: PropTypes.func,
    t: PropTypes.any,
    toggleRightSidebar: PropTypes.func
}

const mapStatetoProps = state => {
    const { layoutType } = state.Layout
    return { layoutType }
}

export default connect(mapStatetoProps, { toggleRightSidebar })(
    withTranslation()(Header)
)
