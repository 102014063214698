import React, { useState, useEffect } from "react"
import Axios from "axios"
import MetaTags from 'react-meta-tags'
import { Alert, Card, CardBody, Col, Container, Row, Button, Input, InputGroup, FormGroup, Label } from "reactstrap"
import { AvForm, AvField, AvGroup } from 'availity-reactstrap-validation'
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useParams } from "react-router-dom"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
// Nir...
import MyFunction from "../common/MyFunction"
import "../common/nirstyle.scss"
import Switch from "react-switch"

function TransactionUpdate() {
    //--- STATES ---

    const { editId } = useParams();
    const [sessionUid, setSessionUid] = useState('')
    const [accountList, setAccountList] = useState([])
    const [subAccountTo, setSubAccountTo] = useState([])
    const [subAccountFrom, setSubAccountFrom] = useState([])
    const [showing, setShowing] = useState('')
    const [editData, setEditData] = useState([])
    const [tranDate, setTranDate] = useState('')
    const [remindDate, setRemindDate] = useState('')
    const [showHide, setshowHide] = useState()

    //--- GET ALL DATA FROM DB & POPULATE DISPLAY LIST ---

    useEffect(() => {
        setSessionUid(MyFunction.loginUid())
        getAllAccounts()
        // alert(editId)
        if (editId == undefined || editId == '') {
            document.getElementById("sub-account-to").style.display = "none";
            document.getElementById("sub-account-from").style.display = "none";
        }
    }, []);

    // --- READ DATA FORM DB ---

    const getAllAccounts = () => {
        Axios.post(`${MyFunction.global.url}/get_accounts.php`, {
            uid: MyFunction.loginUid()
        }).then((response) => {
            // console.log(response.data)
            setAccountList(response.data)
        });
        if (!isNaN(editId)) {
            Axios.get(`${MyFunction.global.url}/get_transaction_detail.php?edit_id=` + editId).then((response) => {
                setEditData(response.data[0])
                // console.log(employee.name)
                // console.log(response.data[0])
                Axios.post(`${MyFunction.global.url}/get_subaccounts.php`, {
                    uid: MyFunction.loginUid(),
                    selected_account_id: response.data[0].to_account_id
                }).then((response) => {
                    // console.log(response.data)
                    setSubAccountTo(response.data)
                });
                Axios.post(`${MyFunction.global.url}/get_subaccounts.php`, {
                    uid: MyFunction.loginUid(),
                    selected_account_id: response.data[0].from_account_id
                }).then((response) => {
                    // console.log(response.data)
                    setSubAccountFrom(response.data)
                });
                setTranDate(response.data[0].trans_on)
                setRemindDate(response.data[0].remind_date)
                document.getElementById("amount").value = MyFunction.toIndianCurrency(response.data[0].amount.replace(/,/g, ""))
            });
        } else {
            setTranDate(MyFunction.getDateToday())
        }
    }

    //--- ADD MAIN TABLE DATA ---

    const submitData = (event, errors, values) => {
        if ((errors == '' || errors == undefined || errors == null)) {
            var amt = document.getElementById("amount").value.replace(/,/g, "")
            // console.log(amt)
            if (amt != "") {
                if (isNaN(editId)) {
                    // alert(tranDate)
                    Axios.post(`${MyFunction.global.url}/insert_transaction.php`, {
                        to_account_id: values.to_account_id,
                        from_account_id: values.from_account_id,
                        to_subaccount_id: values.to_subaccount_id,
                        from_subaccount_id: values.from_subaccount_id,
                        trans_on: tranDate,
                        amount: amt,
                        description: values.description.trim(),
                        remind_date: remindDate,
                        remind_note: values.remind_note,
                        remind_class: values.remind_class,
                        uid: sessionUid,
                    }).then(() => {
                        setShowing(true)
                        setTimeout(() => setShowing(false), 3000)
                        document.myform && document.myform.reset()
                        document.getElementById("amount").value = ""
                    })
                } else {
                    Axios.put(`${MyFunction.global.url}/update_transaction.php`, {
                        edit_id: editId,
                        to_account_id: values.to_account_id,
                        from_account_id: values.from_account_id,
                        to_subaccount_id: values.to_subaccount_id,
                        from_subaccount_id: values.from_subaccount_id,
                        trans_on: tranDate,
                        amount: amt,
                        description: values.description,
                        remind_date: remindDate,
                        remind_note: values.remind_note,
                        remind_class: values.remind_class,
                        uid: sessionUid,
                    }).then(() => {
                        setShowing(true)
                        setTimeout(() => setShowing(false), 3000)
                    })
                }
            } else {
                document.getElementById("amount").focus
            }

        }
    }
    const amountFormatting = () => {
        // console.log(document.getElementById("amount").value)
        var amt = document.getElementById("amount").value
        document.getElementById("amount").value = MyFunction.toIndianCurrency(amt.replace(/,/g, ""))
    }

    const handleToAccountChange = () => {
        var selected_account_id = document.getElementById("to_account_id").value
        // console.log("# " + selected_account_id)
        Axios.post(`${MyFunction.global.url}/get_subaccounts.php`, {
            uid: MyFunction.loginUid(),
            selected_account_id: selected_account_id
        }).then((response) => {
            // console.log(response.data)
            setSubAccountTo(response.data)
        });
    }

    const handleFromAccountChange = () => {
        var selected_account_id = document.getElementById("from_account_id").value
        // console.log("# " + selected_account_id)
        Axios.post(`${MyFunction.global.url}/get_subaccounts.php`, {
            uid: MyFunction.loginUid(),
            selected_account_id: selected_account_id
        }).then((response) => {
            // console.log(response.data)
            setSubAccountFrom(response.data)
        });
    }
    // --- SHOWHIDE CHECKBOX CSS ---

    const Offsymbol = () => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >
                {" "}
                No
            </div>
        )
    }

    const OnSymbol = () => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >
                {" "}
                Yes
            </div>
        )
    }
    const showHideDo = (showHide) => {
        setshowHide(showHide)
        // $("#sub-credit-to").hide()
        // alert(showHide)
        if (showHide == true) {
            document.getElementById("sub-account-to").style.display = "block";
            document.getElementById("sub-account-from").style.display = "block";
        } else {
            document.getElementById("sub-account-to").style.display = "none";
            document.getElementById("sub-account-from").style.display = "none";
        }
    }
    return (
        < React.Fragment >
            <div className="page-content">
                <MetaTags>
                    <title>Insert</title>
                </MetaTags>
                <Container fluid={true}>
                    {isNaN(editId) ?
                        <Breadcrumbs title="Transaction" link1="/transaction-manage" breadcrumbItem="Transaction Add" /> :
                        <Breadcrumbs title="Transaction" link1="/transaction-manage" breadcrumbItem="Transaction Edit" />
                    }
                    {/* Success message... */}
                    {showing ?
                        <div id="alert-success">
                            <Alert color="success" role="alert">
                                Data successfully saved.
                            </Alert>
                        </div> : ""
                    }
                    <Row>
                        <Col lg={12}>
                            <AvForm onSubmit={submitData} id="myform" ref={c => (document.myform = c)}>
                                <div className="mb-3 row">
                                    <Card>
                                        <CardBody>
                                            <AvGroup>
                                                <div className="mb-3 row">
                                                    <label className="col-md-2 col-form-label">Credit To</label>
                                                    <div className="col-md-10" >
                                                        <AvField
                                                            name="to_account_id"
                                                            id="to_account_id"
                                                            type="select"
                                                            autoFocus
                                                            value={editData.to_account_id}
                                                            errorMessage="Select account to credit"
                                                            validate={{ required: { value: true } }}
                                                            className="form-select"
                                                            onChange={handleToAccountChange}
                                                        >
                                                            <option value="">- Select Account To Credit -</option>
                                                            {accountList.map((val) => {
                                                                return (
                                                                    // val.agroup != 'Income' ? <option value={val.id} key={val.id}>{val.account}</option> : ""
                                                                    <option value={val.id} key={val.id}>{val.account}</option>
                                                                )
                                                            })}
                                                        </AvField>
                                                    </div>
                                                </div>
                                            </AvGroup>
                                            <AvGroup id="sub-account-to">
                                                <div className="mb-3 row">
                                                    <label className="col-md-2 col-form-label">Sub Credit To</label>
                                                    <div className="col-md-10" >
                                                        <AvField
                                                            name="to_subaccount_id"
                                                            type="select"
                                                            value={editData.to_subaccount_id}
                                                            errorMessage="Select sub-account to credit"
                                                            className="form-select"
                                                        >
                                                            <option value="">- Select Sub Account To Credit -</option>
                                                            {subAccountTo.map((val) => {
                                                                return (
                                                                    <option value={val.id} key={val.id}>{val.subaccount}</option>
                                                                )
                                                            })}
                                                        </AvField>
                                                    </div>
                                                </div>
                                            </AvGroup>
                                        </CardBody>
                                    </Card>
                                    <Card>
                                        <CardBody>
                                            <AvGroup>
                                                <div className="mb-3 row">
                                                    <label className="col-md-2 col-form-label">Debit From</label>
                                                    <div className="col-md-10" >
                                                        <AvField
                                                            name="from_account_id"
                                                            type="select"
                                                            value={editData.from_account_id}
                                                            errorMessage="Select account to debit from"
                                                            validate={{ required: { value: true } }}
                                                            className="form-select"
                                                            onChange={handleFromAccountChange}
                                                        >
                                                            <option value="">- Select Account To Debit From -</option>
                                                            {accountList.map((val) => {
                                                                return (
                                                                    // val.agroup != 'Expense' ? <option value={val.id} key={val.id}>{val.account}</option> : ""
                                                                    <option value={val.id} key={val.id}>{val.account}</option>
                                                                )
                                                            })}
                                                        </AvField>
                                                    </div>
                                                </div>
                                            </AvGroup>
                                            <AvGroup id="sub-account-from">
                                                <div className="mb-3 row">
                                                    <label className="col-md-2 col-form-label">Sub Credit From</label>
                                                    <div className="col-md-10" >
                                                        <AvField
                                                            name="from_subaccount_id"
                                                            type="select"
                                                            value={editData.from_subaccount_id}
                                                            errorMessage="Select sub-account from credit"
                                                            className="form-select"
                                                        >
                                                            <option value="">- Select Sub Account From Credit -</option>
                                                            {subAccountFrom.map((val) => {
                                                                return (
                                                                    <option value={val.id} key={val.id}>{val.subaccount}</option>
                                                                )
                                                            })}
                                                        </AvField>
                                                    </div>
                                                </div>
                                            </AvGroup>
                                        </CardBody>
                                    </Card>
                                    <Card>
                                        <CardBody>
                                            <AvGroup>
                                                <div className="mb-3 row" >
                                                    <label className="col-md-2 col-form-label">Date</label>
                                                    <div className="col-md-10">
                                                        <Flatpickr
                                                            name="trans_on"
                                                            id="trans_on"
                                                            type="text"
                                                            value={tranDate}
                                                            placeholder="dd M,yyyy"
                                                            options={{
                                                                altInput: true,
                                                                altFormat: "j F, Y",
                                                                dateFormat: "Y-m-d"
                                                            }}
                                                            validate={{ required: true }}
                                                            onChange={() => {
                                                                setTranDate(document.getElementById("trans_on").value)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </AvGroup>
                                            <AvGroup>
                                                <div className="mb-3 row" >
                                                    <label className="col-md-2 col-form-label">Amount</label>
                                                    <div className="col-md-10">
                                                        <Input
                                                            className="form-control"
                                                            type="text"
                                                            id="amount"
                                                            name="amount"
                                                            placeholder="Enter amount"
                                                            onChange={() => {
                                                                amountFormatting()
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </AvGroup>
                                            <AvGroup>
                                                <div className="mb-3 row" >
                                                    <label className="col-md-2 col-form-label">Description</label>
                                                    <div className="col-md-10">
                                                        <AvField
                                                            name="description"
                                                            type="text"
                                                            value={editData.description}
                                                            placeholder="Enter Description"
                                                            errorMessage="Description can't be blank"
                                                            validate={{ required: true }}
                                                        />
                                                    </div>
                                                </div>
                                            </AvGroup>
                                        </CardBody>
                                    </Card>
                                    {/* <Card>
                                        <CardBody>
                                            <AvGroup>
                                                <div className="mb-3 row" >
                                                    <label className="col-md-2 col-form-label">Reminder Date</label>
                                                    <div className="col-md-10">
                                                        <Flatpickr
                                                            name="remind_date"
                                                            id="remind_date"
                                                            type="text"
                                                            value={remindDate}
                                                            placeholder="dd M,yyyy"
                                                            options={{
                                                                altInput: true,
                                                                altFormat: "j F, Y",
                                                                dateFormat: "Y-m-d"
                                                            }}
                                                            validate={{ required: true }}
                                                            onChange={() => {
                                                                setRemindDate(document.getElementById("remind_date").value)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </AvGroup>
                                            <AvGroup>
                                                <div className="mb-3 row" >
                                                    <label className="col-md-2 col-form-label">Reminder Note</label>
                                                    <div className="col-md-10">
                                                        <AvField
                                                            name="remind_note"
                                                            type="text"
                                                            value={editData.remind_note}
                                                            placeholder="Enter reminder note"
                                                        />
                                                    </div>
                                                </div>
                                            </AvGroup>
                                            <AvGroup>
                                                <div className="mb-3 row">
                                                    <label className="col-md-2 col-form-label">Reminder Style</label>
                                                    <div className="col-md-10" >
                                                        <AvField
                                                            name="remind_class"
                                                            type="select"
                                                            value={editData.remind_class}
                                                            className="form-select"
                                                            onChange={handleFromAccountChange}
                                                        >
                                                            <option value="">- Reminder Style -</option>
                                                            <option className="nir-danger" value="bg-danger">Danger</option>
                                                            <option className="nir-success" value="bg-success">Success</option>
                                                            <option className="nir-primary" value="bg-primary">Primary</option>
                                                            <option className="nir-warning" value="bg-warning">Warning</option>
                                                            <option className="nir-info" value="bg-info">Info</option>
                                                            <option className="nir-dark" value="bg-dark">Dark</option>
                                                            <option className="nir-light" value="bg-light">light</option>
                                                        </AvField>
                                                    </div>
                                                </div>
                                            </AvGroup>
                                        </CardBody>
                                    </Card> */}
                                    <Card>
                                        <CardBody>
                                            <AvGroup>
                                                <div className="row">
                                                    <div className="col-3">
                                                        <label className="">Show All</label>
                                                        <div className="">
                                                            <Switch
                                                                name="showHide"
                                                                id="showHide"
                                                                // value={editData.active}
                                                                uncheckedIcon={<Offsymbol />}
                                                                checkedIcon={<OnSymbol />}
                                                                onColor="#02a499"
                                                                onChange={() =>
                                                                    showHideDo(!showHide)
                                                                }
                                                                checked={showHide}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="text-center mt-4">
                                                            <Button color={isNaN(editId) ? "primary" : "danger"} className="btn">SUBMIT</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </AvGroup>
                                        </CardBody>
                                    </Card>
                                </div>
                            </AvForm>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment >
    )
}
export default TransactionUpdate
