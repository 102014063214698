import React, { useState, useEffect } from "react"
import Axios from "axios"
import MetaTags from 'react-meta-tags'
import { Alert, Card, CardBody, Col, Container, Row, Button } from "reactstrap"
import { AvForm, AvField, AvGroup } from 'availity-reactstrap-validation'
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useParams } from "react-router-dom"
// Nir...
import MyFunction from "../common/MyFunction"

function SubAccountUpdate() {

    const { editId } = useParams();

    //--- STATES ---

    const [accountList, setAccountList] = useState([])
    const [showing, setShowing] = useState('')
    const [editData, setEditData] = useState([])
    const [sessionUid, setSessionUid] = useState([])

    //--- GET ALL DATA FROM DB & POPULATE DISPLAY LIST ---

    useEffect(() => {
        setSessionUid(MyFunction.loginUid())
        getAllAccounts()
    }, []);

    // --- READ DATA FORM DB ---

    const getAllAccounts = () => {
        Axios.post(`${MyFunction.global.url}/get_accounts.php`, {
            uid: MyFunction.loginUid()
        }).then((response) => {
            // console.log("### " + response.data + " ###")
            setAccountList(response.data)
        });
        if (!isNaN(editId)) {
            Axios.get(`${MyFunction.global.url}/get_subaccount_detail.php?edit_id=` + editId).then((response) => {
                setEditData(response.data[0])
                // console.log(employee.name)
                // console.log(response.data[0])
            });
        }
    }

    //--- ADD MAIN TABLE DATA ---

    const submitData = (event, errors, values) => {
        // console.log(employeeName)
        if (errors == '' || errors == undefined || errors == null) {
            if (isNaN(editId)) {
                Axios.post(`${MyFunction.global.url}/insert_subaccount.php`, {
                    account: values.account,
                    subaccount: values.subaccount,
                    uid: sessionUid,
                }).then(() => {
                    setShowing(true)
                    setTimeout(() => setShowing(false), 3000)
                })
            } else {
                Axios.put(`${MyFunction.global.url}/update_subaccount.php`, {
                    edit_id: editId,
                    account: values.account,
                    subaccount: values.subaccount,
                    uid: sessionUid,
                }).then(() => {
                    setShowing(true)
                    setTimeout(() => setShowing(false), 3000)
                })
            }
        }
    }

    return (
        < React.Fragment >
            <div className="page-content">
                <MetaTags>
                    <title>Sub-Account</title>
                </MetaTags>
                <Container fluid={true}>
                    {isNaN(editId) ?
                        <Breadcrumbs title="Sub-Account" link1="/sub-account-manage" breadcrumbItem="Sub-Account Add" /> :
                        <Breadcrumbs title="Sub-Account" link1="/sub-account-manage" breadcrumbItem="Sub-Account Edit" />
                    }
                    {/* Success message... */}
                    {showing ?
                        <div id="alert-success">
                            <Alert color="success" role="alert">
                                Data successfully saved.
                            </Alert>
                        </div> : ""
                    }
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <AvForm onSubmit={submitData}>
                                        <div className="mb-3 row">
                                            <AvGroup>
                                                <div className="mb-3 row" >
                                                    <label className="col-md-2 col-form-label">Sub-Account Name</label>
                                                    <div className="col-md-10">
                                                        <AvField
                                                            name="subaccount"
                                                            type="text"
                                                            value={editData.subaccount}
                                                            placeholder="Enter subaccount name"
                                                            errorMessage="Subaccount name can not be blank"
                                                            autoFocus
                                                            validate={{ required: { value: true } }}
                                                        />
                                                    </div>
                                                </div>
                                            </AvGroup>
                                            <AvGroup>
                                                <div className="mb-3 row">
                                                    <label className="col-md-2 col-form-label">Account</label>
                                                    <div className="col-md-10" >
                                                        <AvField
                                                            name="account"
                                                            type="select"
                                                            value={editData.account}
                                                            errorMessage="Select parent account"
                                                            validate={{ required: { value: true } }}
                                                            className="form-select"
                                                        >
                                                            {/* {console.log(accountList)} */}
                                                            <option value="">- Select Parent Account -</option>
                                                            {accountList.map((val) => {
                                                                return (
                                                                    <option value={val.id} key={val.id}>{val.account}</option>
                                                                )
                                                            })}
                                                        </AvField>
                                                    </div>
                                                </div>
                                            </AvGroup>
                                        </div>
                                        <div className="text-center mt-4">
                                            <Button color={isNaN(editId) ? "primary" : "danger"} className="btn">SUBMIT</Button>
                                        </div>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment >
    )
}
export default SubAccountUpdate
