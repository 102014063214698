import React from "react"
import Axios from "axios";
import { Form, Card, Col, Row, CardBody, Input, InputGroup, DropdownItem, DropdownMenu, DropdownToggle, Button, ButtonDropdown } from "reactstrap";
import MetaTags from 'react-meta-tags'
import SweetAlert from "react-bootstrap-sweetalert"
import { Link } from "react-router-dom";
// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
// import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
// import "../common/datatables.scss"
// Nir...
import MyFunction from "../common/MyFunction"
import "../common/nirstyle.scss"

class TransactionManage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            sessionUid: '',
            page: 1,
            sizePerPage: 5,
            recordCount: 0,
            myData: [],
            actionOnId: 0,
            getDeleteConf: false,
            alertDeleted: false,
            fromDate: '',
            toDate: '',
            timePeriod: '-',
            searchData: '',
            sortField: '',
            sortOrder: '',
            periodToggle: false
        }
        this.getAllData = this.getAllData.bind(this)
        this.getRecordCount = this.getRecordCount.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.callDeleteAPI = this.callDeleteAPI.bind(this)
        this.filterData = this.filterData.bind(this)
        this.updateTimeRange = this.updateTimeRange.bind(this)
        this.handleTimePeriodChange = this.handleTimePeriodChange.bind(this)
        this.changeMonth = this.changeMonth.bind(this)
        this.setInitialTimePeriod = this.setInitialTimePeriod.bind(this)
        this.tablePageChange = this.tablePageChange.bind(this)
    }

    componentDidMount() {
        this.setState({ sessionUid: MyFunction.loginUid() })
        // get this month's date
        this.setInitialTimePeriod()
    }

    setInitialTimePeriod() {
        var date = new Date()
        var oneMonthFromToday = new Date(date.getFullYear(), date.getMonth() - 1, date.getDay() + 1)
        var today = date
        this.updateTimeRange(oneMonthFromToday, today)
    }

    // FILTER DATA...
    filterData(event) {
        event.preventDefault()
        this.setState({ timePeriod: '-' })
        this.getRecordCount()
        this.getAllData()
    }

    handleTimePeriodChange(e) {
        // alert(e.target.value)
        var period = e.target.value
        if (period != '-' && period != undefined && period != '') {
            this.setState({ timePeriod: period })
            var date = new Date()
            var startDate, endDate
            if (period == 'tm') { // this month...
                startDate = new Date(date.getFullYear(), date.getMonth(), 1)
                endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0)
                this.updateTimeRange(startDate, endDate)
            } else if (period == 'lm') { // last month...
                startDate = new Date(date.getFullYear(), date.getMonth() - 1, 1)
                endDate = new Date(date.getFullYear(), date.getMonth(), 0)
                this.updateTimeRange(startDate, endDate)
            } else if (period == 'fy') { // this financial year...
                startDate = new Date(MyFunction.getFinancialYearStart())
                endDate = new Date(MyFunction.getFinancialYearEnd())
                this.updateTimeRange(startDate, endDate)
            } else { // begining of time...
                this.updateTimeRange(new Date("2000-01-01"), date)
            }
        }
    }

    updateTimeRange(fromDate, toDate) {
        this.setState({
            fromDate: MyFunction.formatDate(fromDate),
            toDate: MyFunction.formatDate(toDate)
        }, function () {
            this.getRecordCount()
            this.getAllData()
        })
    }

    changeMonth(np) {
        var fromDate = new Date(this.state.fromDate)
        var toDate = new Date(this.state.toDate)
        // alert(fromDate)
        if (np == 'pre') {
            var newDate = new Date(fromDate.getFullYear(), fromDate.getMonth() - 1, fromDate.getDate())
            this.updateTimeRange(newDate, toDate)
        } else if (np == 'nxt') {
            var newDate = new Date(toDate.getFullYear(), toDate.getMonth() + 1, toDate.getDate())
            this.updateTimeRange(fromDate, newDate)
        }
    }

    //--- GET ALL DATA ---

    getRecordCount() {
        Axios.post(`${MyFunction.global.url}/get_all_transaction.php`, {
            count_record: 'Y',
            search_data: this.state.searchData,
            from_date: this.state.fromDate,
            to_date: this.state.toDate,
            uid: this.state.sessionUid
        }).then((response) => {
            // console.log(response.data[0].record_count);
            this.setState({ recordCount: response.data[0].record_count })
        });
    }

    getAllData() {
        // alert(this.state.fromDate + " => " + this.state.toDate)
        Axios.post(`${MyFunction.global.url}/get_all_transaction.php`, {
            start_page: (parseInt(this.state.page) - 1) * parseInt(this.state.sizePerPage),
            size_of_page: this.state.sizePerPage,
            search_data: this.state.searchData,
            from_date: this.state.fromDate,
            to_date: this.state.toDate,
            uid: this.state.sessionUid
        }).then((response) => {
            // console.log(response.data);
            this.setState({ myData: response.data })
            // console.log(this.state.myData);
            // console.log(this.state.myData.length)
        });
    }

    // DELETE DATA...
    handleDelete(e) {
        this.setState({
            actionOnId: e.target.id,
            getDeleteConf: true,
        })
    }

    callDeleteAPI() {
        // alert('id: ' + this.state.actionOnId)
        this.setState({
            getDeleteConf: false
        })
        Axios.delete(`${MyFunction.global.url}/delete_transaction.php?id=${this.state.actionOnId}`).then(() => {
            // alert("Successfully deleted.")
            this.setState({
                // Remove deleted record from datatable...
                myData: this.state.myData.filter((myData) => {
                    // alert(this.state.actionOnId)
                    return myData.id !== this.state.actionOnId
                }),
                getDeleteConf: false,
                actionOnId: 0,
                alertDeleted: true
            })
        });
    }

    tablePageChange(type, { page, sortField, sortOrder, sizePerPage }) {
        // alert(page + " " + sortField + " " + sortOrder + " " + sizePerPage)
        this.setState({
            page: page,
            sortField: sortField,
            sortOrder: sortOrder,
            sizePerPage: sizePerPage,
        }, () => { this.getAllData() });
    }

    render() {
        // console.log(this.state)
        // const { ExportCSVButton } = CSVExport
        const columns = [{
            dataField: 'trans_on',
            text: 'Date',
            sort: true,
            formatter: (dataField) => {
                return (MyFunction.toFormattedDate(dataField));
            },
            headerStyle: { backgroundColor: MyFunction.datatableTitleBG }
        }, {
            dataField: 'to_account',
            text: 'Credit To',
            sort: true,
            headerStyle: { backgroundColor: MyFunction.datatableTitleBG }
        }, {
            dataField: 'to_subaccount',
            text: 'To Sub',
            sort: true,
            headerStyle: { backgroundColor: MyFunction.datatableTitleBG },
            formatter: (dataField) => {
                return (dataField == null ? '---' : dataField);
            },
        }, {
            dataField: 'from_account',
            text: 'Debit From',
            sort: true,
            headerStyle: { backgroundColor: MyFunction.datatableTitleBG }
        }, {
            dataField: 'from_subaccount',
            text: 'From Sub',
            sort: true,
            headerStyle: { backgroundColor: MyFunction.datatableTitleBG },
            formatter: (dataField) => {
                return (dataField == null ? '---' : dataField);
            },
        }, {
            dataField: 'amount',
            text: 'Amount',
            sort: false,
            align: 'right',
            headerAlign: 'right',
            formatter: (dataField) => {
                return (MyFunction.toIndianCurrency(dataField));
            },
            headerStyle: { backgroundColor: MyFunction.datatableTitleBG }
        }, {
            dataField: 'description',
            text: 'Description',
            sort: false,
            headerStyle: { backgroundColor: MyFunction.datatableTitleBG }
        }, {
            dataField: 'id',
            text: "Action",
            headerAlign: 'left',
            align: 'center',
            csvExport: false,
            formatter: (dataField) => (
                <div className="d-flex gap-3">
                    <Link to={`/transaction-update/${dataField}`} className="text-success">
                        <i className="mdi mdi-pencil font-size-18" id={dataField} />
                    </Link>
                    <Link to="#" className="text-danger">
                        <i className="mdi mdi-delete font-size-18" id={dataField} onClick={this.handleDelete} />
                    </Link>
                </div>
            ),
            headerStyle: { backgroundColor: MyFunction.datatableTitleBG }
        },];

        const ServerDataTable = ({ data, page, sizePerPage, totalSize }) => (
            <div>
                <BootstrapTable
                    remote
                    keyField="id"
                    data={data}
                    columns={columns}
                    pagination={paginationFactory({ page, sizePerPage, totalSize })}
                    onTableChange={this.tablePageChange}
                />
            </div>
        );

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>MyM-Account</title>
                    </MetaTags>
                    <div className="container-fluid">
                        <Breadcrumbs title="Dashboard" link1="/dashboard" breadcrumbItem="Transaction Manage" />
                        <Card>
                            <CardBody>
                                <Form onSubmit={this.filterData} className="row align-items-center">
                                    <Row>
                                        <Col sm={2} xs={12} className="space-around">
                                            <Input
                                                value={this.state.fromDate}
                                                onChange={(e) => this.setState({ fromDate: e.target.value, timePeriod: '-' })}
                                                type="date"
                                                className="form-control"
                                            />
                                        </Col>
                                        <Col sm={3} xs={12} align="center" className="space-around" >
                                            {/* <Col className="col-md-3 align-items-center"> */}
                                            <button onClick={() => this.changeMonth("pre")} type="button" className="btn btn-outline-warning">
                                                &nbsp;<i className="bx bx-skip-previous font-size-20 align-middle me-1"></i>
                                            </button>&nbsp;&nbsp;
                                            <button onClick={() => this.setInitialTimePeriod()} type="button" className="btn btn-outline-success">
                                                &nbsp;<i className="bx bx-revision font-size-20 align-middle me-1"></i>
                                            </button>&nbsp;&nbsp;
                                            <button onClick={() => this.changeMonth("nxt")} type="button" className="btn btn-outline-warning">
                                                &nbsp;<i className="bx bx-skip-next font-size-20 align-middle me-1"></i>
                                            </button>
                                        </Col>
                                        <Col sm={2} xs={12} className="space-around">
                                            <Input
                                                value={this.state.toDate}
                                                onChange={(e) => this.setState({ toDate: e.target.value, timePeriod: '-' })}
                                                type="date"
                                                className="form-control"
                                            />
                                        </Col>
                                        <Col sm={2} xs={10} className="space-around">
                                            <InputGroup>
                                                <div className="input-group-text">
                                                    <i className="bx bx-search-alt search-icon" />
                                                </div>
                                                <input
                                                    onChange={(e) => this.setState({ searchData: e.target.value })}
                                                    type="input"
                                                    className="form-control"
                                                    placeholder="Search..."
                                                />
                                            </InputGroup>
                                        </Col>
                                        <Col sm={1} xs={2} aligh="right" className="space-around">
                                            <button type="submit" className="btn btn-outline-primary">
                                                <i className="bx bx-search-alt font-size-20 align-middle"></i>
                                            </button>
                                        </Col>
                                        <Col sm={1} xs={2} align="left" className="space-around">
                                            <ButtonDropdown
                                                onClickCapture={this.handleTimePeriodChange}
                                                isOpen={this.state.periodToggle}
                                                toggle={() => this.setState({ periodToggle: !this.state.periodToggle })}
                                            >
                                                <DropdownToggle caret color="primary" >
                                                    <i className="mdi mdi-calendar-multiple"></i>
                                                    <i className="mdi mdi-chevron-down"></i>
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem value="tm">This Month</DropdownItem>
                                                    <DropdownItem value="lm">Last Month</DropdownItem>
                                                    <DropdownItem value="fy">Financial Year</DropdownItem>
                                                    <DropdownItem divider />
                                                    <DropdownItem value="bot">Beginning Of Time</DropdownItem>
                                                </DropdownMenu>
                                            </ButtonDropdown>
                                        </Col>
                                        <Col sm={1} xs={10} align="right" className="space-around">
                                            <Link to="/transaction-update" className="text-danger">
                                                <div className="button-items">
                                                    <button type="button" className="btn btn-primary btn-label">
                                                        <i className="mdi mdi-plus label-icon"></i>Add
                                                    </button>
                                                </div>
                                            </Link>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <React.Fragment>
                                            <div className="table-responsive">
                                                {this.state.myData.length > 0 ?
                                                    <ServerDataTable
                                                        data={this.state.myData}
                                                        page={this.state.page}
                                                        sizePerPage={this.state.sizePerPage}
                                                        totalSize={this.state.recordCount}
                                                    />
                                                    : "Nothing to show. Please refine your search."}
                                            </div>
                                        </React.Fragment>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    {this.state.alertDeleted ? (
                        <SweetAlert
                            success
                            title="Successfully Deleted"
                            timeout={1000}
                            showConfirm={false}
                            onConfirm={() => this.setState({ alertDeleted: false })}
                        />
                    ) : null}
                    {this.state.getDeleteConf ? (
                        <SweetAlert
                            title="Delete the record?"
                            warning
                            showCancel
                            confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            onConfirm={this.callDeleteAPI}
                            onCancel={() =>
                                this.setState({
                                    getDeleteConf: false,
                                })}
                        >
                            Action can&apos;t be reverted!
                        </SweetAlert>
                    ) : null}
                </div>
            </React.Fragment >
        )
    }
}
export default TransactionManage