import React, { Component } from "react"
import Axios from "axios";
import MetaTags from 'react-meta-tags';

// Redux
import { Link } from "react-router-dom"

import { Row, Col, CardBody, Card, Container, Alert } from "reactstrap"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// import images
import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/nir/inr.png"
import lightlogo from "../../assets/images/nir/inr.png"

// nir...
import MyFunction from "../common/MyFunction"

class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showMessage: false,
            // url: 'http://localhost/mym-react/code/server' // local...
        }
    }
    componentDidMount() {
        sessionStorage.clear()
    }

    render() {
        const submitData = (event, errors, values) => {
            if (errors == '' || errors == undefined || errors == null) {
                Axios.post(`${MyFunction.global.url}/user_login.php`, {
                    uid: values.uid,
                    pwd: values.pwd,
                }).then((response) => {
                    if (!response.data[0]) {
                        this.setState({
                            showMessage: true
                        })
                    } else {
                        // console.log(response.data[0].name)
                        window.sessionStorage.setItem("userId", response.data[0].uid);
                        window.sessionStorage.setItem("userName", response.data[0].name);
                        // alert(window.sessionStorage.getItem("userId"))
                        event.preventDefault();
                        window.location.href = '/dashboard'
                    }
                });

            }
        }

        return (
            <React.Fragment>
                <MetaTags>
                    <title>Login | MyM</title>
                </MetaTags>
                <div className="home-btn d-none d-sm-block">
                    <Link to="/" className="text-dark">
                        <i className="bx bx-home h2" />
                    </Link>
                </div>
                <div className="account-pages my-5 pt-sm-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="overflow-hidden">
                                    <div className="bg-primary bg-soft">
                                        <Row>
                                            <Col className="col-7">
                                                <div className="p-4">
                                                    <h5>Welcome to MyM</h5>
                                                    <p>Sign in to continue to MyM</p>
                                                </div>
                                            </Col>
                                            <Col className="col-5 align-self-end">
                                                <img src={profile} alt="" className="img-fluid" />
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="pt-0">
                                        <div className="auth-logo">
                                            <Link to="/" className="auth-logo-light">
                                                <div className="avatar-md profile-user-wid mb-4">
                                                    <span className="avatar-title rounded-circle bg-light">
                                                        <img
                                                            src={lightlogo}
                                                            alt=""
                                                            className="rounded-circle"
                                                            height="34"
                                                        />
                                                    </span>
                                                </div>
                                            </Link>
                                            <Link to="/" className="auth-logo-dark">
                                                <div className="avatar-md profile-user-wid mb-4">
                                                    <span className="avatar-title rounded-circle bg-light">
                                                        <img
                                                            src={logo}
                                                            alt=""
                                                            className="rounded-circle"
                                                            height="34"
                                                        />
                                                    </span>
                                                </div>
                                            </Link>
                                        </div>
                                        {/* Success message... */}
                                        {
                                            this.state.showMessage ?
                                                <div id="alert-success">
                                                    <Alert color="danger" role="alert">
                                                        Username and/or Password is wrong!!!
                                                    </Alert>
                                                </div>
                                                : ""
                                        }
                                        <div className="p-2">
                                            <AvForm className="form-horizontal" onSubmit={submitData}>
                                                <div className="mb-3">
                                                    <AvField
                                                        name="uid"
                                                        label="Username"
                                                        className="form-control"
                                                        placeholder="Enter username"
                                                        type="text"
                                                        required
                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    <AvField
                                                        name="pwd"
                                                        label="Password"
                                                        type="password"
                                                        required
                                                        placeholder="Enter password"
                                                    />
                                                </div>

                                                <div className="form-check">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="customControlInline"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="customControlInline"
                                                    >
                                                        Remember me
                                                    </label>
                                                </div>

                                                <div className="mt-3 d-grid">
                                                    <button
                                                        className="btn btn-primary btn-block"
                                                        type="submit"
                                                    >
                                                        Log In
                                                    </button>
                                                </div>

                                                <div className="mt-4 text-center">
                                                    <h5 className="font-size-14 mb-3">Sign in with</h5>

                                                    <ul className="list-inline">
                                                        <li className="list-inline-item">
                                                            <Link
                                                                to="#"
                                                                className="social-list-item bg-primary text-white border-primary"
                                                            >
                                                                <i className="mdi mdi-facebook" />
                                                            </Link>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <Link
                                                                to="#"
                                                                className="social-list-item bg-info text-white border-info"
                                                            >
                                                                <i className="mdi mdi-twitter" />
                                                            </Link>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <Link
                                                                to="#"
                                                                className="social-list-item bg-danger text-white border-danger"
                                                            >
                                                                <i className="mdi mdi-google" />
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>

                                                <div className="mt-4 text-center">
                                                    <Link to="#" className="text-muted">
                                                        <i className="mdi mdi-lock me-1" /> Forgot your password?
                                                    </Link>
                                                </div>
                                            </AvForm>
                                        </div>
                                    </CardBody>
                                </Card>
                                <div className="mt-5 text-center">
                                    <p>
                                        Don&apos;t have an account ?
                                        <Link to="#" className="fw-medium text-primary" >
                                            Signup now
                                        </Link>
                                    </p>
                                    <p>© {new Date().getFullYear()} MyM</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default Login
