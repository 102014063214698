import React, { useState, useEffect } from "react"
import MetaTags from 'react-meta-tags'
import Axios from "axios"
import SweetAlert from "react-bootstrap-sweetalert"
import { Card, CardBody, CardText, CardTitle, Col, Container, Row, Media, } from "reactstrap"
import Breadcrumbs from "../components/Common/Breadcrumb"
import { Link } from "react-router-dom"
// Nir...
import MyFunction from "./common/MyFunction"
function Dashboard() {

    //--- STATES ---

    const [sessionUid, setSessionUid] = useState('')
    const [showMsg, setShowMsg] = useState(false)
    const [reminderList, setReminderList] = useState([])

    useEffect(() => {
        setSessionUid(MyFunction.loginUid())
        getAllReminders()
    }, []);

    // --- READ DATA FORM DB ---

    const getAllReminders = () => {
        Axios.get(`${MyFunction.global.url}/get_reminder_recent.php?uid=` + MyFunction.loginUid()).then((response) => {
            console.log(response.data)
            setReminderList(response.data)
        })
    }

    const backupDb = () => {
        setShowMsg(true)
        window.open(MyFunction.global.url + '/db_export.php', "_blank")
    }

    return (
        < React.Fragment >
            <div className="page-content">
                <MetaTags>
                    <title>Dashboard</title>
                </MetaTags>
                {showMsg ? (
                    <SweetAlert
                        success
                        title="Backup Successful"
                        timeout={1000}
                        showConfirm={false}
                        onConfirm={() => setShowMsg(false)}
                    />
                ) : null}
                <Container fluid={true}>
                    <Breadcrumbs title="MyM" breadcrumbItem="Dashboard" />
                    <Row>
                        <Col lg="12">
                            <Card style={{ background: '#B01F57' }}>
                                <CardBody>
                                    <CardTitle className="mb-4 h4 text-white">
                                        {reminderList.length > 0 ? <i className="bx bxs-bell-ring me-3" style={{ fontSize: '2em' }}></i> : ""}
                                        UPCOMING EVENTS
                                    </CardTitle>
                                    {
                                        reminderList.length > 0 ?
                                            reminderList.map((val) => {
                                                return (
                                                    <Media className="faq-box mb-2" key={val.id} style={{ marginLeft: '40px' }}>
                                                        <div className="faq-icon me-3">
                                                            <i className="bx bx-heart-circle font-size-20 text-white" />
                                                        </div>
                                                        <Media body>
                                                            <h5 className="font-size-15 text-white">
                                                                {val.remind_date}
                                                            </h5>
                                                            <p className="text-white">
                                                                {val.remind_note}
                                                            </p>
                                                        </Media>
                                                    </Media>
                                                )
                                            })
                                            :
                                            <Media className="faq-box mb-2">
                                                <div className="faq-icon me-3">
                                                    <i className="fas fa-glass-cheers font-size-20 text-white" />
                                                </div>
                                                <div className="faq-icon me-3">
                                                    <i className="fas fa-hands-wash font-size-20 text-white" />
                                                </div>
                                                <div className="faq-icon me-3">
                                                    <i className="fas fa-mug-hot font-size-20 text-white" />
                                                </div>
                                                <Media body>
                                                    <h5 className="font-size-15 text-white">
                                                        Relax all done.
                                                    </h5>
                                                </Media>
                                            </Media>

                                    }

                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={4}>
                            <Link to="/transaction-update" >
                                <Card color="info" className="text-black">
                                    <CardBody>
                                        <CardTitle className="h5 mt-0 mb-4 text-black">
                                            <i className="mdi mdi-cart-variant me-3" style={{ fontSize: '2em' }}></i>
                                            TRANSACTION
                                        </CardTitle>
                                        <CardText> Add a New Transaction</CardText>
                                    </CardBody>
                                </Card>
                            </Link>
                        </Col>
                        <Col lg={4}>
                            <Link to="/analysis" >
                                <Card color="success" className="text-black">
                                    <CardBody>
                                        <CardTitle className="h5 mt-0 mb-4 text-black">
                                            <i className="mdi mdi-chart-bar me-3" style={{ fontSize: '2em' }}></i>
                                            BALANCE SHEET
                                        </CardTitle>
                                        <CardText> Transaction Analysis </CardText>
                                    </CardBody>
                                </Card>
                            </Link>
                        </Col>
                        <Col lg={4}>
                            <Link onClick={backupDb} >
                                <Card color="warning" className="text-black">
                                    <CardBody>
                                        <CardTitle className="h5 mt-0 mb-4 text-black">
                                            <i className="mdi mdi-content-save-all me-3" style={{ fontSize: '2em' }}></i>
                                            BACKUP
                                        </CardTitle>
                                        <CardText> Take Database Backup </CardText>
                                    </CardBody>
                                </Card>
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment >
    )
}
export default Dashboard
