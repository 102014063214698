const MyFunction = {

    //--- LOCATION OF SERVER CODE ---
    global: {
        // url: 'http://localhost/mym-react/server'     // local...
        url: 'https://mym.bitsinfoservices.com/server'       // server-fewfootfall-hostinger...
    },

    // Comma separated number...

    moneyFormatIndia: function (num, dec) {
        var temp;
        temp = num.toLocaleString('en-IN', {
            maximumFractionDigits: dec,
            style: 'currency',
            currency: 'INR'
        })
        return (temp)
    },

    // Comma separated number...

    toIndianCurrency: function (num) {
        var n1, n2;
        n1 = num.split('.');
        n2 = n1[1] || null;
        n1 = n1[0].replace(/(\d)(?=(\d\d)+\d$)/g, "$1,");
        num = n2 ? n1 + ' . ' + n2 : n1;
        return ((num));
    },

    // Get argument/parameter from URL...

    getArgument(url, argPosition) {
        var urlArray = url.split('/')
        return (urlArray[argPosition + 1])
    },

    // Date format - Indian with weekday...

    toFormattedDate: function (date) {
        // var options = { year: 'numeric', month: 'long', day: 'numeric', weekday: 'short' }; // week-day...
        var options = { year: 'numeric', month: 'short', day: 'numeric' };
        var myDate = new Date(date);
        return (myDate.toLocaleDateString("en-IN", options))
    },

    // Check where the user has logged-in ...

    loginUid: function () {
        var userId = window.sessionStorage.getItem("userId")
        // alert(userId)
        // if (userId == null || userId == '') {
        //     window.location.href = '/login'
        // }
        return (userId)
    },

    // date format => 12 Jan,2021
    readableDate: function (date) {
        var fullMonth = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

        var month = fullMonth[date.getMonth()]
        var day = date.getDate()
        var year = date.getFullYear()

        if (day.length < 2) day = '0' + day

        return (day + " " + month + ", " + year)
    },

    // date formatting to yyyy-mm-dd...

    formatDate: function (date) {
        var month = '' + (date.getMonth() + 1)
        var day = '' + date.getDate()
        var year = date.getFullYear()

        if (month.length < 2) month = '0' + month
        if (day.length < 2) day = '0' + day

        return [year, month, day].join('-')
    },

    // date & time formatting to yyyy-mm-dd-hh-mm-ss...

    addZero: function (i) {
        if (i < 10) { i = "0" + i }
        return i;
    },

    formatDatetime: function (date) {
        var month = '' + (date.getMonth() + 1)
        var day = '' + date.getDate()
        var year = date.getFullYear()
        var hour = MyFunction.addZero(date.getHours())
        var min = MyFunction.addZero(date.getMinutes())
        var sec = MyFunction.addZero(date.getSeconds())

        if (month.length < 2) month = '0' + month
        if (day.length < 2) day = '0' + day

        return [year, month, day, hour, min, sec].join('-')
    },

    //--- get today's date ---

    getDateToday: function () {
        var year, month, day

        var today = new Date();

        month = today.getMonth() + 1
        month < 10 ? month = '0' + month : month
        // month = new Intl.DateTimeFormat('en', { month: 'short' }).format(today);

        day = today.getDate()
        day < 10 ? day = '0' + day : day

        year = today.getFullYear()
        // alert(month)
        return [year, month, day].join('-')
    },
    // get start date of this financial year...

    getFinancialYearStart: function () {
        var year = ""
        var today = new Date();

        if ((today.getMonth() + 1) <= 3) {
            year = today.getFullYear() - 1
        } else {
            year = today.getFullYear()
        }
        return [year, "04", "01"].join('-')
    },

    // get end date of this financial year...

    getFinancialYearEnd: function () {
        var year = ""
        var today = new Date();

        if ((today.getMonth() + 1) <= 3) {
            year = today.getFullYear()
        } else {
            year = today.getFullYear() + 1
        }
        return [year, "03", "31"].join('-')
    },

    datatableTitleBG: '#262B3C'
}
export default MyFunction;