import React from "react"
import Axios from "axios";
import { Form, Card, Col, Row, CardBody, Input } from "reactstrap";
import MetaTags from 'react-meta-tags'
import SweetAlert from "react-bootstrap-sweetalert"
import { Link } from "react-router-dom";

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
// import "../common/datatables.scss"

// Nir...
import MyFunction from "../common/MyFunction"
// import "../common/nirstyle.scss"

class TransactionAnalyse extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            sessionUid: '',
            page: 1,
            sizePerPage: 10,
            myData: [],
            actionOnId: 0,
            getDeleteConf: false,
            alertDeleted: false,
            fromDate: '',
            toDate: '',
            timePeriod: '-',
            accountId: 0,
            accountType: ''
        }
        this.getAllData = this.getAllData.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.callDeleteAPI = this.callDeleteAPI.bind(this)
        this.filterData = this.filterData.bind(this)
        this.updateTimeRange = this.updateTimeRange.bind(this)
        this.handleTimePeriodChange = this.handleTimePeriodChange.bind(this)
        this.changeMonth = this.changeMonth.bind(this)
        this.setInitialTimePeriod = this.setInitialTimePeriod.bind(this)
        this.getAssetTotal = this.getAssetTotal.bind(this)
        this.getLiablityTotal = this.getLiablityTotal.bind(this)
        this.getAssetLiablityDiff = this.getAssetLiablityDiff.bind(this)
    }

    componentDidMount() {
        this.sessionUid = MyFunction.loginUid()
        this.accountType = MyFunction.getArgument(window.location.pathname, 1)
        this.accountId = MyFunction.getArgument(window.location.pathname, 2)
        // get this month's date
        this.setInitialTimePeriod()
        console.log(this.accountType + this.accountId)
    }

    setInitialTimePeriod() {
        var date = new Date()
        var oneMonthFromToday = new Date(date.getFullYear(), date.getMonth() - 1, date.getDay() + 1)
        var today = date
        this.updateTimeRange(oneMonthFromToday, today)
    }

    // FILTER DATA...
    filterData(event) {
        event.preventDefault()
        this.setState({ timePeriod: '-' })
        this.getAllData()
    }

    // Asset Total...
    getAssetTotal() {
        var total = 0
        this.state.myData.map((val) => {
            if (val.to_acc_amt) {
                var temp = val.to_acc_amt.split('#');
                if (temp[0] == this.accountId) {
                    total += parseInt(val.amount)
                }
            }
        })
        // console.log(MyFunction.moneyFormatIndia(total))
        return (MyFunction.moneyFormatIndia(total, 0))
    }

    // Liability Total...
    getLiablityTotal() {
        var total = 0
        this.state.myData.map((val) => {
            if (val.from_acc_amt) {
                var temp = val.from_acc_amt.split('#');
                if (temp[0] == this.accountId) {
                    total += parseInt(val.amount)
                }
            }
            // console.log(total)
        })
        return (MyFunction.moneyFormatIndia(total, 0))
    }

    // Get Difference...
    getAssetLiablityDiff() {
        var total = 0
        this.state.myData.map((val) => {
            if (val.from_acc_amt) {
                var temp = val.from_acc_amt.split('#');
                if (temp[0] == this.accountId) {
                    total -= parseInt(val.amount)
                } else {
                    total += parseInt(val.amount)
                }
            }
        })
        return (MyFunction.moneyFormatIndia(total, 0))
    }

    handleTimePeriodChange(e) {
        // alert(e.target.value)
        var period = e.target.value
        this.setState({ timePeriod: period })
        if (period != '-') {
            var date = new Date();
            var startDate, endDate
            if (period == 'tm') { // this month...
                startDate = new Date(date.getFullYear(), date.getMonth(), 1)
                endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0)
                this.updateTimeRange(startDate, endDate)
            } else if (period == 'lm') { // last month...
                startDate = new Date(date.getFullYear(), date.getMonth() - 1, 1)
                endDate = new Date(date.getFullYear(), date.getMonth(), 0)
                this.updateTimeRange(startDate, endDate)
            } else if (period == 'fy') { // this financial year...
                startDate = new Date(MyFunction.getFinancialYearStart())
                endDate = new Date(MyFunction.getFinancialYearEnd())
                this.updateTimeRange(startDate, endDate)
            } else { // begining of time...
                this.updateTimeRange(new Date("2000-01-01"), date)
            }
        }
    }

    updateTimeRange(fromDate, toDate) {
        this.setState({
            fromDate: MyFunction.formatDate(fromDate),
            toDate: MyFunction.formatDate(toDate)
        }, function () {
            this.getAllData()
        })
    }

    changeMonth(np) {
        var fromDate = new Date(this.state.fromDate)
        var toDate = new Date(this.state.toDate)
        // alert(fromDate)
        if (np == 'pre') {
            var newDate = new Date(fromDate.getFullYear(), fromDate.getMonth() - 1, fromDate.getDate())
            this.updateTimeRange(newDate, toDate)
        } else if (np == 'nxt') {
            var newDate = new Date(toDate.getFullYear(), toDate.getMonth() + 1, toDate.getDate())
            this.updateTimeRange(fromDate, newDate)
        }
    }

    // GET ALL DATA
    getAllData() {
        // alert(this.sessionUid)
        // alert(MyFunction.global.url)
        // alert(this.state.fromDate + " => " + this.state.toDate)
        var serverScript = (this.accountType == "account" ? "get_transaction_4_account.php" : "get_transaction_4_subaccount.php")
        console.log(serverScript)
        Axios.post(`${MyFunction.global.url}/${serverScript}`, {
            from_date: this.state.fromDate,
            to_date: this.state.toDate,
            uid: this.sessionUid,
            account_id: this.accountId,
        }).then((response) => {
            console.log(response.data);
            this.setState({ myData: response.data })
            // console.log(this.state.myData);
            // console.log(this.state.myData.length)
        });
    }

    // DELETE DATA...
    handleDelete(e) {
        this.setState({
            actionOnId: e.target.id,
            getDeleteConf: true,
        })
    }

    callDeleteAPI() {
        // alert('id: ' + this.state.actionOnId)
        this.setState({
            getDeleteConf: false
        })
        Axios.delete(`${MyFunction.global.url}/delete_transaction.php?id=${this.state.actionOnId}`).then(() => {
            // alert("Successfully deleted.")
            this.setState({
                // Remove deleted record from datatable...
                myData: this.state.myData.filter((myData) => {
                    // alert(this.state.actionOnId)
                    return myData.id !== this.state.actionOnId
                }),
                getDeleteConf: false,
                actionOnId: 0,
                alertDeleted: true
            })
        });
    }

    render() {
        const { ExportCSVButton } = CSVExport;
        const columns = [{
            dataField: 'trans_on',
            text: 'DATE',
            sort: true,
            headerStyle: { backgroundColor: MyFunction.datatableTitleBG },
            formatter: (dataField) => {
                return (MyFunction.toFormattedDate(dataField));
            },
            footer: 'TOTAL',
            footerStyle: { backgroundColor: MyFunction.datatableTitleBG }
        }, {
            dataField: 'to_acc_amt',
            text: 'IN',
            sort: false,
            csvExport: false,
            align: 'right',
            headerAlign: 'right',
            formatter: (dataField) => {
                var temp = dataField.split('#');
                if (temp[0] == this.accountId) {
                    return (MyFunction.toIndianCurrency(temp[1]));
                } else {
                    return ("---");
                }
            },
            headerStyle: { backgroundColor: MyFunction.datatableTitleBG },
            footer: this.getAssetTotal,
            footerAlign: 'right',
            footerStyle: { backgroundColor: MyFunction.datatableTitleBG }
        }, {
            dataField: 'from_acc_amt',
            text: 'OUT',
            sort: false,
            csvExport: false,
            align: 'right',
            headerAlign: 'right',
            formatter: (dataField) => {
                if (dataField) {
                    var temp = dataField.split('#');
                    if (temp[0] == this.accountId) {
                        return (MyFunction.toIndianCurrency(temp[1]));
                    } else {
                        return ("---");
                    }
                } else {
                    return ("---");
                }
            },
            headerStyle: { backgroundColor: MyFunction.datatableTitleBG },
            footer: this.getLiablityTotal,
            footerAlign: 'right',
            footerStyle: { backgroundColor: MyFunction.datatableTitleBG }
        }, {
            dataField: 'to_acc',
            text: 'CREDIT TO',
            sort: false,
            headerStyle: { backgroundColor: MyFunction.datatableTitleBG },
            footer: '',
            footerStyle: { backgroundColor: MyFunction.datatableTitleBG }
        }, {
            dataField: 'from_acc',
            text: 'DEBIT FROM',
            sort: false,
            headerStyle: { backgroundColor: MyFunction.datatableTitleBG },
            footer: this.getAssetLiablityDiff,
            footerStyle: { backgroundColor: MyFunction.datatableTitleBG }
        }, {
            dataField: 'description',
            text: 'DESCRIPTION',
            sort: false,
            headerStyle: { backgroundColor: MyFunction.datatableTitleBG },
            footer: '',
            footerStyle: { backgroundColor: MyFunction.datatableTitleBG }
        }, {
            dataField: 'id',
            text: "ACTION",
            headerAlign: 'left',
            align: 'center',
            csvExport: false,
            formatter: (dataField) => (
                <div className="d-flex gap-3">
                    <Link to={`/transaction-update/${dataField}`} className="text-success">
                        <i className="mdi mdi-pencil font-size-18" id={dataField} />
                    </Link>
                    <Link to="#" className="text-danger">
                        <i className="mdi mdi-delete font-size-18" id={dataField} onClick={this.handleDelete} />
                    </Link>
                </div>
            ),
            headerStyle: { backgroundColor: MyFunction.datatableTitleBG },
            footer: '',
            footerStyle: { backgroundColor: MyFunction.datatableTitleBG }
        },];

        const defaultSorted = [{
            // dataField: 'id',
            // order: 'asc'
        }];

        const pageOptions = {
            sizePerPage: this.state.sizePerPage,
            totalSize: this.state.myData.length, // replace later with size(customers),
            custom: true,
        }

        // Custom Pagination Toggle
        const sizePerPageList = [
            { text: '10', value: 10 },
            { text: '50', value: 20 },
            { text: 'All', value: this.state.myData.length }
        ];

        // Select All Button operation
        const selectRow = {
            mode: 'checkbox'
        }

        const { SearchBar } = Search;

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>MyM-Account</title>
                    </MetaTags>
                    <div className="container-fluid">
                        <Breadcrumbs title="Dashboard" link1="/dashboard" breadcrumbItem="Transaction Analyse" />
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <Form onSubmit={this.filterData}>
                                            <div className="mb-3 row">
                                                <div className="col-md-2 col-form-label">
                                                    <Input
                                                        value={this.state.fromDate}
                                                        onChange={(e) => this.setState({ fromDate: e.target.value, timePeriod: '-' })}
                                                        type="date"
                                                        className="form-control"
                                                    />
                                                </div>
                                                <div className="col-md-3 col-form-label align-items-center">
                                                    <button onClick={() => this.changeMonth("pre")} type="button" className="btn btn-outline-warning">
                                                        &nbsp;<i className="bx bx-skip-previous font-size-20 align-middle me-2"></i>
                                                    </button>&nbsp;&nbsp;
                                                    <button onClick={() => this.setInitialTimePeriod()} type="button" className="btn btn-outline-success">
                                                        &nbsp;<i className="bx bx-revision font-size-20 align-middle me-2"></i>
                                                    </button>&nbsp;&nbsp;
                                                    <button onClick={() => this.changeMonth("nxt")} type="button" className="btn btn-outline-warning">
                                                        &nbsp;<i className="bx bx-skip-next font-size-20 align-middle me-2"></i>
                                                    </button>
                                                </div>
                                                <div className="col-md-2 col-form-label">
                                                    <Input
                                                        value={this.state.toDate}
                                                        onChange={(e) => this.setState({ toDate: e.target.value, timePeriod: '-' })}
                                                        type="date"
                                                        className="form-control"
                                                    />
                                                </div>
                                                <div className="col-md-2 col-form-label">
                                                    <label className="visually-hidden" htmlFor="autoSizingSelect">Preference</label>
                                                    <select
                                                        value={this.state.timePeriod}
                                                        onChange={this.handleTimePeriodChange}
                                                        className="form-select"
                                                    >
                                                        <option value="-" defaultValue> - Time Period - </option>
                                                        <option value="tm">This Month</option>
                                                        <option value="lm">Last Month</option>
                                                        <option value="fy">Financial Year</option>
                                                        <option value="bot">Beginning Of Time</option>
                                                    </select>
                                                </div>
                                                <div className="col-md-2 col-form-label">
                                                    <button type="submit" className="btn btn-primary">
                                                        &nbsp;<i className="bx bx-search-alt font-size-20 align-middle me-2"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody>
                                        <PaginationProvider
                                            pagination={paginationFactory(pageOptions)}
                                        // keyField='id'
                                        // columns={columns}
                                        // data={this.state.myData} 
                                        >
                                            {({ paginationProps, paginationTableProps }) => (
                                                <ToolkitProvider
                                                    keyField='id'
                                                    columns={columns}
                                                    data={this.state.myData}
                                                    rounded={true}
                                                    search
                                                    condensed
                                                    striped
                                                    exportCSV
                                                >
                                                    {toolkitProps => (
                                                        <React.Fragment>
                                                            <div className="mb-3 row">
                                                                <Col>
                                                                    <div className="search-box me-2 mb-2 d-inline-block" >
                                                                        <div className="position-relative">
                                                                            <SearchBar {...toolkitProps.searchProps} />
                                                                            <i className="bx bx-search-alt search-icon" />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col>
                                                                    <div className="position-relative" style={{ float: 'right' }}>
                                                                        <ExportCSVButton className="btn-rounded btn-outline-success" {...toolkitProps.csvProps}>Export CSV</ExportCSVButton>
                                                                    </div>
                                                                </Col>
                                                            </div>
                                                            <Row>
                                                                <Col xl="12">
                                                                    <div className="table-responsive">
                                                                        <BootstrapTable
                                                                            // keyField={"id"}
                                                                            responsive
                                                                            hover={true}
                                                                            defaultSorted={defaultSorted}
                                                                            bordered={false}
                                                                            striped={false}
                                                                            headerWrapperClasses={"table-dark"}
                                                                            {...toolkitProps.baseProps}
                                                                            {...paginationTableProps}

                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row className="align-items-md-center mt-30">
                                                                <Col className="inner-custom-pagination d-flex">
                                                                    <div className="d-inline">
                                                                        <SizePerPageDropdownStandalone{...paginationProps} />
                                                                    </div>
                                                                    <div className="text-md-right ms-auto">
                                                                        <PaginationListStandalone {...paginationProps} />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </React.Fragment>
                                                    )}
                                                </ToolkitProvider>
                                            )
                                            }</PaginationProvider>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    {this.state.alertDeleted ? (
                        <SweetAlert
                            success
                            title="Successfully Deleted"
                            timeout={1000}
                            showConfirm={false}
                            onConfirm={() => this.setState({ alertDeleted: false })}
                        />
                    ) : null}
                    {this.state.getDeleteConf ? (
                        <SweetAlert
                            title="Delete the record?"
                            warning
                            showCancel
                            confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            onConfirm={this.callDeleteAPI}
                            onCancel={() =>
                                this.setState({
                                    getDeleteConf: false,
                                })}
                        >
                            Action can&apos;t be reverted!
                        </SweetAlert>
                    ) : null}
                </div>
            </React.Fragment>
        )
    }
}
export default TransactionAnalyse