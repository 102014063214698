import React from 'react';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import { Button, Label, FormGroup } from 'reactstrap';

export default class Test extends React.Component {
    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {};
    }

    handleSubmit(event, errors, values) {
        this.setState({ errors, values });
        // alert(values.name)
        this.setState({ values: 'zzz' })
    }

    render() {
        return (
            <div>
                <AvForm onSubmit={this.handleSubmit}>
                    <br /><br /><br /><br /><br /><br /><br /><br />
                    <AvGroup>
                        <Label for="example">Name</Label>
                        <AvInput name="name" id="example" required />
                        <AvFeedback>This is an error!</AvFeedback>
                    </AvGroup>
                    <AvField type="select" name="select" label="Option" helpMessage="Idk, this is an example. Deal with it!">
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                    </AvField>
                    <FormGroup>
                        <Button>Submit</Button>
                    </FormGroup>
                </AvForm>
                {this.state.values && <div>
                    <h5>Submission values</h5>
                    Invalid: {this.state.errors.join(', ')}<br />
                    Values: <pre>{JSON.stringify(this.state.values, null, 2)}</pre>
                </div>}
            </div>
        );
    }
}