import React from "react";
import { Redirect } from "react-router-dom";

import Login from "../pages/Authentication/Login";

import AccountManage from "../pages/Account/AccountManage";
import AccountUpdate from "../pages/Account/AccountUpdate";

import SubAccountManage from "../pages/SubAccount/SubAccountManage";
import SubAccountUpdate from "../pages/SubAccount/SubAccountUpdate";

import TransactionManage from "../pages/Transaction/TransactionManage";
import TransactionUpdate from "../pages/Transaction/TransactionUpdate";
import TransactionAnalyse from "../pages/Transaction/TransactionAnalyse";

import Dashboard from "../pages/Dashboard";
import Test from "../pages/Test";

import Analysis from "../pages/Analyse/Analysis";
import IncExpBar from "../pages/Analyse/IncExpBar";
import IncExpLine from "../pages/Analyse/IncExpLine";
import Income from "../pages/Analyse/Income";
import Expense from "../pages/Analyse/Expense";

import ReminderCalendar from "../pages/Analyse/ReminderCalendar";

const authProtectedRoutes = [
    { path: "/dashboard", component: Dashboard },
    { path: "/test", component: Test },

    { path: "/analysis", component: Analysis },
    { path: "/inc-exp-bar", component: IncExpBar },
    { path: "/inc-exp-line", component: IncExpLine },
    { path: "/income", component: Income },
    { path: "/expense", component: Expense },

    { path: "/reminder-calendar", component: ReminderCalendar },

    { path: "/account-manage", component: AccountManage },
    { path: "/account-update", component: AccountUpdate },
    { path: "/account-update/:editId", component: AccountUpdate },

    { path: "/sub-account-manage", component: SubAccountManage },
    { path: "/sub-account-update", component: SubAccountUpdate },
    { path: "/sub-account-update/:editId", component: SubAccountUpdate },

    { path: "/transaction-manage", component: TransactionManage },
    { path: "/transaction-update", component: TransactionUpdate },
    { path: "/transaction-update/:editId", component: TransactionUpdate },
    { path: "/transaction-analyse/:accountType/:accountId", component: TransactionAnalyse },

    // this route should be at the end of all other routes
    // eslint-disable-next-line react/display-name
    { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const publicRoutes = [
    { path: "/login", component: Login },
];

export { authProtectedRoutes, publicRoutes };
