import React, { useState, useEffect } from "react"
import Axios from "axios"
import MetaTags from 'react-meta-tags'
import { Alert, Card, CardBody, Col, Container, Row, Button } from "reactstrap"
import { AvForm, AvField, AvGroup, AvFeedback, } from 'availity-reactstrap-validation'
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useParams } from "react-router-dom"
// Nir...
import MyFunction from "../common/MyFunction"
import Switch from "react-switch"

function AccountUpdate() {
    //--- URLs ---

    const { editId } = useParams();

    //--- STATES ---

    const [sessionUid, setSessionUid] = useState([])
    const [agroupList, setAgroupList] = useState([])
    const [liquidityList, setliquidityList] = useState([])
    const [showing, setShowing] = useState('')
    const [editData, setEditData] = useState([])
    const [activeSwitch, setActiveSwitch] = useState()

    //--- GET ALL DATA FROM DB & POPULATE DISPLAY LIST ---

    useEffect(() => {
        // Check & get Session User...
        setSessionUid(MyFunction.loginUid())
        Axios.get(`${MyFunction.global.url}/get_all_agroup.php`).then((response) => {
            // console.log(response.data)
            setAgroupList(response.data)
        });
        Axios.get(`${MyFunction.global.url}/get_all_liquidity.php`).then((response) => {
            // console.log(response.data)
            setliquidityList(response.data);
        });
        if (!isNaN(editId)) {
            // alert("hi")
            Axios.get(`${MyFunction.global.url}/get_account_detail.php?edit_id=` + editId).then((response) => {
                setEditData(response.data[0])
                setActiveSwitch(response.data[0].active == 'Y' ? true : false)
                // console.log(employee.name)
                // console.log(response.data[0])
            });
        }
    }, []);

    //--- ADD MAIN TABLE DATA ---

    const submitData = (event, errors, values) => {
        // console.log(employeeName)
        // alert(activeSwitch)
        if (errors == '' || errors == undefined || errors == null) {
            if (isNaN(editId)) {
                Axios.post(`${MyFunction.global.url}/insert_account.php`, {   // INSERT...
                    account: values.account,
                    agroup: values.agroup,
                    open_bal: values.open_bal,
                    uid: sessionUid,
                    liquidity: values.liquidity,
                    min_bal: values.min_bal,
                    active: activeSwitch == true ? 'Y' : 'N',
                }).then(() => {
                    setShowing(true)
                    setTimeout(() => setShowing(false), 3000)
                })
            } else {
                Axios.put(`${MyFunction.global.url}/update_account.php`, {    // UPDATE...
                    edit_id: editId,
                    account: values.account,
                    agroup: values.agroup,
                    open_bal: values.open_bal,
                    uid: sessionUid,
                    liquidity: values.liquidity,
                    min_bal: values.min_bal,
                    active: activeSwitch == true ? 'Y' : 'N',
                }).then(() => {
                    setShowing(true)
                    setTimeout(() => setShowing(false), 3000)
                })
            }
        }
    }

    // --- ACTIVE CHECKBOX CSS ---

    const Offsymbol = () => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >
                {" "}
                No
            </div>
        )
    }

    const OnSymbol = () => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >
                {" "}
                Yes
            </div>
        )
    }

    return (
        < React.Fragment >
            <div className="page-content">
                <MetaTags>
                    <title>Insert</title>
                </MetaTags>
                <Container fluid={true}>
                    {isNaN(editId) ?
                        <Breadcrumbs title="Account" link1="/account-manage" breadcrumbItem="Account Add" /> :
                        <Breadcrumbs title="Account" link1="/account-manage" breadcrumbItem="Account Edit" />
                    }
                    {/* Success message... */}
                    {showing ?
                        <div id="alert-success">
                            <Alert color="success" role="alert">
                                Data successfully saved.
                            </Alert>
                        </div> : ""
                    }
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <AvForm onSubmit={submitData}>
                                        <div className="mb-3 row">
                                            <AvGroup>
                                                <div className="mb-3 row" >
                                                    <label className="col-md-2 col-form-label">Account Name</label>
                                                    <div className="col-md-10">
                                                        {/* <InputGroup> */}
                                                        {/* <div className="input-group-text bx bxs-bank" /> */}
                                                        <AvField
                                                            name="account"
                                                            type="text"
                                                            value={editData.account}
                                                            placeholder="Enter account name"
                                                            errorMessage="Account name can not be blank"
                                                            autoFocus
                                                            // className="form-control"
                                                            validate={{ required: { value: true } }}
                                                        />
                                                        {/* </InputGroup> */}
                                                    </div>
                                                </div>
                                            </AvGroup>
                                            <AvGroup>
                                                <div className="mb-3 row">
                                                    <label className="col-md-2 col-form-label">Account Type</label>
                                                    <div className="col-md-10" >
                                                        <AvField
                                                            name="agroup"
                                                            type="select"
                                                            value={editData.agroup}
                                                            errorMessage="Select an account type."
                                                            validate={{ required: { value: true } }}
                                                            className="form-select"
                                                        >
                                                            <option value="">- Select Account Type -</option>
                                                            {agroupList.map((val) => {
                                                                return (
                                                                    <option value={val.id} key={val.id}>{val.name}</option>
                                                                )
                                                            })}
                                                        </AvField>
                                                    </div>
                                                </div>
                                            </AvGroup>
                                            <AvGroup>
                                                <div className="mb-3 row">
                                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label">Opening Balance</label>
                                                    <div className="col-md-10">
                                                        <AvField
                                                            name="open_bal"
                                                            type="text"
                                                            placeholder="Enter opening balance"
                                                            errorMessage="Opening balance must be numeric"
                                                            value={isNaN(editData.open_bal) ? '0' : editData.open_bal}
                                                            validate={{
                                                                required: true,
                                                                pattern: { value: "[+-]?([0-9]*[.])?[0-9]+" },
                                                            }}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </AvGroup>
                                            <AvGroup>
                                                <div className="mb-3 row">
                                                    <label className="col-md-2 col-form-label">Liquidity</label>
                                                    <div className="col-md-10" >
                                                        <AvField
                                                            name="liquidity"
                                                            type="select"
                                                            value={editData.liquidity}
                                                            errorMessage="Select an liquidity."
                                                            required
                                                            className="form-select"
                                                        >
                                                            <option value="">- Select Account Liquidity Filter -</option>
                                                            {liquidityList.map((val) => {
                                                                return (
                                                                    <option value={val.id} key={val.id}>{val.name}</option>
                                                                )
                                                            })}
                                                        </AvField>
                                                    </div>
                                                </div>
                                            </AvGroup>
                                            <AvGroup>
                                                <div className="mb-3 row">
                                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label">Minimum Balance</label>
                                                    <div className="col-md-10">
                                                        <AvField
                                                            name="min_bal"
                                                            type="text"
                                                            placeholder="Enter minimum balance"
                                                            errorMessage="Minimum balance must be numeric"
                                                            value={isNaN(editData.min_bal) ? '0' : editData.min_bal}
                                                            validate={{
                                                                required: true,
                                                                pattern: { value: "[+-]?([0-9]*[.])?[0-9]+" },
                                                            }}
                                                        />
                                                        <AvFeedback>Minimum balance must be numeric</AvFeedback>
                                                    </div>
                                                </div>
                                            </AvGroup>
                                            <AvGroup>
                                                <div className="mb-3 row">
                                                    <label className="col-md-2 col-form-label">Active</label>
                                                    <div className="col-md-10">
                                                        <Switch
                                                            name="active"
                                                            // value={editData.active}
                                                            uncheckedIcon={<Offsymbol />}
                                                            className="me-1 mb-sm-8 mb-2"
                                                            checkedIcon={<OnSymbol />}
                                                            onColor="#02a499"
                                                            onChange={() =>
                                                                setActiveSwitch(!activeSwitch)
                                                            }
                                                            checked={activeSwitch}
                                                        />
                                                    </div>
                                                </div>
                                            </AvGroup>
                                        </div>
                                        <div className="text-center mt-4">
                                            <Button color={isNaN(editId) ? "primary" : "danger"} className="btn">SUBMIT</Button>
                                        </div>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment >
    )
}
export default AccountUpdate
