import React from "react"
import Axios from "axios"
import { Card, Col, Row, CardBody } from "reactstrap"
import MetaTags from 'react-meta-tags'
import { Link } from "react-router-dom"
import Switch from "react-switch"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit'

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

// Nir...
import MyFunction from "../common/MyFunction"
import { OffSymbol, OnSymbol } from "../common/MyStyle"
import { toString } from "lodash"
import "../common/nirstyle.scss"
import loader from "../../assets/images/nir/loader.gif";

class Analysis extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            sessionUid: '',
            page: 1,
            sizePerPage: 1000,
            myData: [],
            liquidityList: [],
            actionOnId: 0,
            getDeleteConf: false,
            alertDeleted: false,
            timePeriod: 30,
            loading: false,
            minBalanceAdd: false,
        }
        this.getAllData = this.getAllData.bind(this)
        this.getAssetTotal = this.getAssetTotal.bind(this)
        this.getLiablityTotal = this.getLiablityTotal.bind(this)
        this.getAssetLiablityDiff = this.getAssetLiablityDiff.bind(this)
        this.handleTimePeriodChange = this.handleTimePeriodChange.bind(this)
    }

    componentDidMount() {
        this.getAllData()
        this.getLiquidityList()
    }

    // Asset Total...
    getAssetTotal() {
        var total = 0
        this.state.myData.map((val) => {
            if (parseInt(val.tot_amt) > 0) {
                total += parseInt(val.tot_amt)
            }
        })
        return (MyFunction.toIndianCurrency(toString(total)))
    }

    // Liability Total...
    getLiablityTotal() {
        var total = 0
        this.state.myData.map((val) => {
            if (parseInt(val.tot_amt) < 0) {
                total += parseInt(val.tot_amt)
            }
        })
        if (total == 0) {
            return ('---')
        } else {
            return (MyFunction.toIndianCurrency(toString(-total)))
        }
    }

    // Get Difference...
    getAssetLiablityDiff() {
        var total = 0
        this.state.myData.map((val) => {
            total += parseInt(val.tot_amt)
        })
        return (MyFunction.toIndianCurrency(toString(total)))
    }

    // Get All Data...
    getAllData() {
        this.setState({ loading: true })
        Axios.post(`${MyFunction.global.url}/get_analysis.php`, {
            uid: MyFunction.loginUid(),
            liquidity_id: this.state.timePeriod,
            minBalanceAdd: this.state.minBalanceAdd == true ? 'Y' : 'N',
        }).then((response) => {
            // console.log(response.data);
            this.setState({ myData: response.data, loading: false })
            // console.log(this.state.myData);
            // console.log(this.state.myData.length)
        });
    }

    getLiquidityList() {
        Axios.get(`${MyFunction.global.url}/get_all_liquidity.php`, {
            // uid: sessionUid,
        }).then((response) => {
            this.setState({ liquidityList: response.data })
        });
    }

    // Handle Time Period Change...
    handleTimePeriodChange(e) {
        // alert(e.target.value)
        this.setState({ timePeriod: e.target.value }, () => {
            this.getAllData()
        })
    }

    render() {
        const { ExportCSVButton } = CSVExport

        const columns = [{
            dataField: 'name',
            text: 'ACCOUNT',
            headerStyle: { backgroundColor: MyFunction.datatableTitleBG },
            footer: 'TOTAL',
            footerStyle: { backgroundColor: MyFunction.datatableTitleBG }
        }, {
            dataField: 'tot_amt',
            text: 'ASSET',
            align: 'right',
            headerStyle: { backgroundColor: MyFunction.datatableTitleBG },
            headerAlign: 'right',
            formatter: (dataField) => {
                if (dataField > 0) {
                    return (MyFunction.toIndianCurrency(toString(Math.round(dataField))))
                } else {
                    return ("---")
                }
            },
            footer: this.getAssetTotal,
            footerAlign: 'right',
            footerStyle: { backgroundColor: MyFunction.datatableTitleBG }
        }, {
            dataField: 'tot_amt',
            text: 'LIABILITY',
            headerStyle: { backgroundColor: MyFunction.datatableTitleBG },
            align: 'right',
            headerAlign: 'right',
            formatter: (dataField) => {
                if (dataField < 0) {
                    return (MyFunction.toIndianCurrency(toString(Math.round(-dataField))))
                } else {
                    return ("---")
                }
            },
            footer: this.getLiablityTotal,
            footerAlign: 'right',
            footerStyle: { backgroundColor: MyFunction.datatableTitleBG }
        }, {
            dataField: 'id',
            text: "ANALYSE",
            csvExport: false,
            headerStyle: { backgroundColor: MyFunction.datatableTitleBG },
            headerAlign: 'center',
            align: 'center',
            formatter: (dataField) => (
                <Link to={`/transaction-analyse/account/${dataField}`} className="text-warning">
                    <i className="mdi mdi-file-eye font-size-18" id={dataField} />
                </Link>
            ),
            footer: this.getAssetLiablityDiff,
            footerAlign: 'center',
            footerStyle: { backgroundColor: MyFunction.datatableTitleBG }
        },];

        const defaultSorted = [{
            dataField: 'name',
            order: 'asc'
        }];

        const pageOptions = {
            sizePerPage: this.state.sizePerPage,
            totalSize: this.state.myData.length, // replace later with size(customers),
            custom: true,
        }

        const { SearchBar } = Search;

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>MyM-Account</title>
                    </MetaTags>
                    <div className="container-fluid">
                        <Breadcrumbs title="Dashboard" link1="/dashboard" breadcrumbItem="Analysis" />
                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody>
                                        <PaginationProvider
                                            pagination={paginationFactory(pageOptions)}
                                            columns={columns}
                                            data={this.state.myData}
                                        >
                                            {({ paginationProps, paginationTableProps }) => (
                                                <ToolkitProvider
                                                    keyField='id'
                                                    columns={columns}
                                                    data={this.state.myData}
                                                    rounded={true}
                                                    search
                                                    exportCSV
                                                >
                                                    {toolkitProps => (
                                                        <React.Fragment>
                                                            <div className="mb-3 row">
                                                                <Col>
                                                                    <div className="search-box me-2 mb-2 d-inline-block" >
                                                                        <div className="position-relative">
                                                                            <SearchBar {...toolkitProps.searchProps} />
                                                                            <i className="bx bx-search-alt search-icon" />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col>
                                                                    <div className=" me-2 mb-2 d-inline-block">
                                                                        <label className="visually-hidden" htmlFor="autoSizingSelect">Preference</label>
                                                                        <select
                                                                            value={this.state.timePeriod}
                                                                            onChange={this.handleTimePeriodChange}
                                                                            className="form-select"
                                                                        >
                                                                            <option value="-" defaultValue> - Time Period - </option>
                                                                            {this.state.liquidityList.map((val) => {
                                                                                return (
                                                                                    <option value={val.id} key={val.id}>{val.name}</option>
                                                                                )
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                                </Col>
                                                                <Col>
                                                                    <div className="d-inline-block">
                                                                        <Switch
                                                                            uncheckedIcon={<OffSymbol />}
                                                                            checkedIcon={<OnSymbol />}
                                                                            onColor="#02a499"
                                                                            onChange={() =>
                                                                                this.setState({ minBalanceAdd: !this.state.minBalanceAdd }, () => {
                                                                                    this.getAllData()
                                                                                })
                                                                            }
                                                                            checked={this.state.minBalanceAdd}
                                                                        />
                                                                        <label className="col-form-label"> &nbsp; Add Minimum Balance</label>
                                                                    </div>
                                                                </Col>
                                                                <Col>
                                                                    <Link to="/transaction-update" className="text-danger">
                                                                        <div className="button-items">
                                                                            <button type="button" className="btn btn-primary btn-label">
                                                                                <i className="mdi mdi-plus label-icon"></i>Add Transaction
                                                                            </button>
                                                                        </div>
                                                                    </Link>
                                                                </Col>
                                                                <Col>
                                                                    <div className="position-relative" style={{ float: 'right' }}>
                                                                        <ExportCSVButton className="btn-rounded btn-outline-success" {...toolkitProps.csvProps}>Export CSV</ExportCSVButton>
                                                                    </div>
                                                                </Col>
                                                            </div>
                                                            <Row>
                                                                {this.state.loading ?
                                                                    <div className="loader">
                                                                        {/* using css * not running at server...
                                                                         <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div> */}
                                                                        <img className="img" src={loader} />
                                                                    </div>
                                                                    : ""
                                                                }
                                                                <Col xl="12">
                                                                    <div className="table-responsive">
                                                                        <BootstrapTable
                                                                            // keyField={"id"}
                                                                            responsive
                                                                            hover={true}
                                                                            defaultSorted={defaultSorted}
                                                                            bordered={false}
                                                                            striped={false}
                                                                            headerWrapperClasses={"table-dark"}
                                                                            {...toolkitProps.baseProps}
                                                                            {...paginationTableProps}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </React.Fragment>
                                                    )}</ToolkitProvider>
                                            )}</PaginationProvider>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment >
        )
    }
}
export default Analysis