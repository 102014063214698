import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Collapse } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import classname from "classnames";

//i18n
import { withTranslation } from "react-i18next";

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        let matchingMenuItem = null;
        const ul = document.getElementById("navigation");
        const items = ul.getElementsByTagName("a");
        for (let i = 0; i < items.length; ++i) {
            if (this.props.location.pathname === items[i].pathname) {
                matchingMenuItem = items[i];
                break;
            }
        }
        if (matchingMenuItem) {
            this.activateParentDropdown(matchingMenuItem);
        }
    }

    activateParentDropdown = item => {
        item.classList.add("active");
        const parent = item.parentElement;
        if (parent) {
            parent.classList.add("active"); // li
            const parent2 = parent.parentElement;
            parent2.classList.add("active"); // li
            const parent3 = parent2.parentElement;
            if (parent3) {
                parent3.classList.add("active"); // li
                const parent4 = parent3.parentElement;
                if (parent4) {
                    parent4.classList.add("active"); // li
                    const parent5 = parent4.parentElement;
                    if (parent5) {
                        parent5.classList.add("active"); // li
                        const parent6 = parent5.parentElement;
                        if (parent6) {
                            parent6.classList.add("active"); // li
                        }
                    }
                }
            }
        }
        return false;
    };

    render() {
        return (
            <React.Fragment>
                <div className="topnav">
                    <div className="container-fluid">
                        <nav
                            className="navbar navbar-light navbar-expand-lg topnav-menu"
                            id="navigation"
                        >
                            <Collapse
                                isOpen={this.props.menuOpen}
                                className="navbar-collapse"
                                id="topnav-menu-content"
                            >
                                <ul className="navbar-nav">
                                    {/* Dashboard */}
                                    <li className="nav-item dropdown">
                                        <Link
                                            className="nav-link dropdown-toggle arrow-none"
                                            onClick={e => {
                                                this.setState({ isDashboard: !this.state.isDashboard });
                                            }}
                                            to="/dashboard"
                                        >
                                            <i className="bx bxs-dashboard me-2" />
                                            {this.props.t("Dashboard")} {this.props.menuOpen}
                                        </Link>
                                    </li>
                                    {/* Account */}
                                    <li className="nav-item dropdown">
                                        <Link
                                            className="nav-link dropdown-toggle arrow-none"
                                            onClick={e => {
                                                e.preventDefault();
                                                this.setState({ isAccount: !this.state.isAccount });
                                            }}
                                            to="/#"
                                        >
                                            <i className="bx bx-file me-2" />
                                            {this.props.t("Account")} {this.props.menuOpen}
                                            <div className="arrow-down" />
                                        </Link>
                                        <div
                                            className={classname("dropdown-menu", {
                                                show: this.state.isAccount,
                                            })}
                                        >
                                            <Link to="/account-manage" className="dropdown-item">
                                                <i className="bx bx bx-list-ul me-2" />
                                                {this.props.t("Manage")}
                                            </Link>
                                            <Link to="/account-update" className="dropdown-item">
                                                <i className="bx bx-list-plus me-2" />
                                                {this.props.t("Add")}
                                            </Link>
                                        </div>
                                    </li>
                                    {/* Sub Account */}
                                    <li className="nav-item dropdown">
                                        <Link
                                            className="nav-link dropdown-toggle arrow-none"
                                            onClick={e => {
                                                e.preventDefault();
                                                this.setState({ isSubAccount: !this.state.isSubAccount });
                                            }}
                                            to="/#"
                                        >
                                            <i className="bx bxs-copy-alt me-2" />
                                            {this.props.t("Sub Account")} {this.props.menuOpen}
                                            <div className="arrow-down" />
                                        </Link>
                                        <div
                                            className={classname("dropdown-menu", {
                                                show: this.state.isSubAccount,
                                            })}
                                        >
                                            <Link to="/sub-account-manage" className="dropdown-item">
                                                <i className="bx bx bx-list-ul me-2" />
                                                {this.props.t("Manage")}
                                            </Link>
                                            <Link to="/sub-account-update" className="dropdown-item">
                                                <i className="bx bx-list-plus me-2" />
                                                {this.props.t("Add")}
                                            </Link>
                                        </div>
                                    </li>
                                    {/* Transaction */}
                                    <li className="nav-item dropdown">
                                        <Link
                                            className="nav-link dropdown-toggle arrow-none"
                                            onClick={e => {
                                                e.preventDefault();
                                                this.setState({ isTransaction: !this.state.isTransaction });
                                            }}
                                            to="/#"
                                        >
                                            <i className="bx bx-cart me-2" />
                                            {this.props.t("Transaction")} {this.props.menuOpen}
                                            <div className="arrow-down" />
                                        </Link>
                                        <div
                                            className={classname("dropdown-menu", {
                                                show: this.state.isTransaction,
                                            })}
                                        >
                                            <Link to="/transaction-manage" className="dropdown-item">
                                                <i className="bx bx bx-list-ul me-2" />
                                                {this.props.t("Manage")}
                                            </Link>
                                            <Link to="/transaction-update" className="dropdown-item">
                                                <i className="bx bx-list-plus me-2" />
                                                {this.props.t("Add")}
                                            </Link>
                                        </div>
                                    </li>
                                    {/* Balance Sheet */}
                                    <li className="nav-item dropdown">
                                        <Link
                                            className="nav-link dropdown-toggle arrow-none"
                                            onClick={e => {
                                                e.preventDefault();
                                                this.setState({ isTransaction: !this.state.isTransaction });
                                            }}
                                            to="/#"
                                        >
                                            <i className="bx bx bxs-flask me-2" />
                                            {this.props.t("Graphical Analysis")} {this.props.menuOpen}
                                            <div className="arrow-down" />
                                        </Link>
                                        <div
                                            className={classname("dropdown-menu", {
                                                show: this.state.isTransaction,
                                            })}
                                        >
                                            <Link to="/inc-exp-bar" className="dropdown-item">
                                                <i className="bx bx-bar-chart me-2" />
                                                {this.props.t("Income-Expense-Bar")}
                                            </Link>
                                            <Link to="/inc-exp-line" className="dropdown-item">
                                                <i className="bx bx-line-chart me-2" />
                                                {this.props.t("Income-Expense-Line")}
                                            </Link>
                                            <Link to="/income" className="dropdown-item">
                                                <i className="bx bx-trending-up me-2" />
                                                {this.props.t("Income")}
                                            </Link>
                                            <Link to="/expense" className="dropdown-item">
                                                <i className="bx bx-trending-down me-2" />
                                                {this.props.t("Expense")}
                                            </Link>
                                        </div>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <Link
                                            className="nav-link dropdown-toggle arrow-none"
                                            onClick={e => {
                                                this.setState({ isDashboard: !this.state.isDashboard });
                                            }}
                                            to="/analysis"
                                        >
                                            <i className="bx bx-spreadsheet me-2" />
                                            {this.props.t("Balance Sheet")} {this.props.menuOpen}
                                        </Link>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <Link
                                            className="nav-link dropdown-toggle arrow-none"
                                            onClick={e => {
                                                this.setState({ isDashboard: !this.state.isDashboard });
                                            }}
                                            to="/reminder-calendar"
                                        >
                                            <i className="bx bx-calendar-check me-2" />
                                            {this.props.t("Remainder")} {this.props.menuOpen}
                                        </Link>
                                    </li>
                                </ul>
                            </Collapse>
                        </nav>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

Navbar.propTypes = {
    location: PropTypes.object,
    menuOpen: PropTypes.any,
    t: PropTypes.any,
};

export default withRouter(withTranslation()(Navbar));