import React from "react"
import Axios from "axios";
import { Row, Col, Card, CardBody, Button, Badge } from "reactstrap"
import MetaTags from 'react-meta-tags'
import SweetAlert from "react-bootstrap-sweetalert"
import { Link } from "react-router-dom";
// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
// Nir...
import MyFunction from "../common/MyFunction"

class SubAccountManage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            sessionUid: '',
            page: 1,
            sizePerPage: 10,
            myData: [],
            actionOnId: 0,
            getDeleteConf: false,
            alertDeleted: false,
        }
        this.getAllData = this.getAllData.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.callDeleteAPI = this.callDeleteAPI.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
    }

    componentDidMount() {
        this.sessionUid = MyFunction.loginUid()
        this.getAllData()
    }

    // GET ALL DATA
    getAllData() {
        Axios.get(`${MyFunction.global.url}/get_all_subaccount.php`).then((response) => {
            // console.log(response.data);
            this.setState({ myData: response.data })
            // console.log(this.state.myData);
            // console.log(this.state.myData.length)
        });
    }

    // EDIT DATA...
    handleEdit(e) {
        var actionOnId = e.target.id
        // alert('id: ' + actionOnId)
        window.location.href = "sub-account-update/" + actionOnId
    }

    // DELETE DATA...
    handleDelete(e) {
        this.setState({
            actionOnId: e.target.id,
            getDeleteConf: true,
        })
    }

    callDeleteAPI() {
        // alert('id: ' + this.state.actionOnId)
        this.setState({
            getDeleteConf: false
        })
        Axios.delete(`${MyFunction.global.url}/delete_subaccount.php?id=${this.state.actionOnId}`).then(() => {
            // alert("Successfully deleted.")
            this.setState({
                // Remove deleted record from datatable...
                myData: this.state.myData.filter((myData) => {
                    // alert(this.state.actionOnId)
                    return myData.id !== this.state.actionOnId
                }),
                getDeleteConf: false,
                actionOnId: 0,
                alertDeleted: true
            })
        });
    }

    render() {
        const columns = [{
            dataField: 'subaccount',
            text: 'Sub-Account',
            sort: true,
            headerStyle: { backgroundColor: MyFunction.datatableTitleBG }
        }, {
            dataField: 'account',
            text: 'Parent-Account',
            sort: true,
            headerStyle: { backgroundColor: MyFunction.datatableTitleBG }
        }, {
            dataField: 'id',
            text: "Edit",
            formatter: (dataField) => (
                <Link to={`/sub-account-update/${dataField}`} >
                    <Button
                        type="button"
                        color="primary"
                        className="btn-sm btn-rounded"
                        id={dataField}
                    > Edit
                    </Button>
                </Link>
            ),
            headerStyle: { backgroundColor: MyFunction.datatableTitleBG }
        }, {
            dataField: 'id',
            text: "Delete",
            formatter: (dataField) => (
                <Button
                    type="button"
                    color="danger"
                    className="btn-sm btn-rounded"
                    onClick={this.handleDelete}
                    id={dataField}
                > Delete
                </Button>
            ),
            headerStyle: { backgroundColor: MyFunction.datatableTitleBG }
        }, {
            dataField: 'id',
            text: "Analysis",
            formatter: (dataField) => (
                <Link to={`/transaction-analyse/subaccount/${dataField}`} >
                    <Button
                        type="button"
                        color="success"
                        className="btn-sm btn-rounded"
                        id={dataField}
                    > Analysis
                    </Button>
                </Link>
            ),
            headerStyle: { backgroundColor: MyFunction.datatableTitleBG }
        },];

        const defaultSorted = [{
            dataField: 'id',
            order: 'asc'
        }];

        const pageOptions = {
            sizePerPage: this.state.sizePerPage,
            totalSize: this.state.myData.length, // replace later with size(customers),
            custom: true,
        }

        // Custom Pagination Toggle
        const sizePerPageList = [
            { text: '5', value: 5 },
            { text: '10', value: 10 },
            { text: '15', value: 15 },
            { text: '20', value: 20 },
            { text: '25', value: 25 },
            { text: 'All', value: this.state.myData.length }
        ];

        // Select All Button operation
        const selectRow = {
            mode: 'checkbox'
        }

        const { SearchBar } = Search;

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>MyM-Account</title>
                    </MetaTags>
                    <div className="container-fluid">
                        <Breadcrumbs title="Dashboard" link1="/dashboard" breadcrumbItem="Sub-Account Manage" />
                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody>
                                        <PaginationProvider
                                            pagination={paginationFactory(pageOptions)}
                                            keyField='id'
                                            columns={columns}
                                            data={this.state.myData}
                                        >
                                            {({ paginationProps, paginationTableProps }) => (
                                                <ToolkitProvider
                                                    keyField='id'
                                                    columns={columns}
                                                    data={this.state.myData}
                                                    rounded={true}
                                                    search
                                                >
                                                    {toolkitProps => (
                                                        <React.Fragment>
                                                            <Row className="mb-2">
                                                                <Col className="space-around">
                                                                    <div className="search-box d-inline-block" >
                                                                        <SearchBar {...toolkitProps.searchProps} />
                                                                        <i className="bx bx-search-alt search-icon space-around" />
                                                                    </div>
                                                                </Col>
                                                                <Col align="right">
                                                                    <div className="col-form-label">
                                                                        <Link to="/sub-account-update" className="text-danger">
                                                                            <div className="button-items">
                                                                                <button type="button" className="btn btn-primary btn-rounded">
                                                                                    <i className="mdi mdi-plus label-icon"></i> Add New
                                                                                </button>
                                                                            </div>
                                                                        </Link>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xl="12">
                                                                    <div className="table-responsive">
                                                                        <BootstrapTable
                                                                            keyField={"id"}
                                                                            responsive
                                                                            hover={true}
                                                                            defaultSorted={defaultSorted}
                                                                            bordered={false}
                                                                            striped={false}
                                                                            headerWrapperClasses={"table-dark"}
                                                                            noDataIndication="Nothing to show."
                                                                            {...toolkitProps.baseProps}
                                                                            {...paginationTableProps}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row className="align-items-md-center mt-30">
                                                                <Col className="inner-custom-pagination d-flex">
                                                                    <div className="d-inline">
                                                                        <SizePerPageDropdownStandalone{...paginationProps} />
                                                                    </div>
                                                                    <div className="text-md-right ms-auto">
                                                                        <PaginationListStandalone {...paginationProps} />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </React.Fragment>
                                                    )}
                                                </ToolkitProvider>
                                            )
                                            }</PaginationProvider>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    {this.state.alertDeleted ? (
                        <SweetAlert
                            success
                            title="Successfully Deleted"
                            timeout={1000}
                            showConfirm={false}
                            onConfirm={() => this.setState({ alertDeleted: false })}
                        />
                    ) : null}
                    {this.state.getDeleteConf ? (
                        <SweetAlert
                            title="Delete the record?"
                            warning
                            showCancel
                            confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            onConfirm={this.callDeleteAPI}
                            onCancel={() =>
                                this.setState({
                                    getDeleteConf: false,
                                })}
                        >
                            Action can&apos;t be reverted!
                        </SweetAlert>
                    ) : null}
                </div>
            </React.Fragment >
        )
    }
}
export default SubAccountManage